import { Inject, Injectable } from '@angular/core';
import { Storage, StorageReference, UploadTask, ref, uploadBytesResumable } from '@angular/fire/storage';
import { arrayUnion } from '@angular/fire/firestore';
import { BehaviorSubject, firstValueFrom, skipWhile, combineLatest } from 'rxjs';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import * as Rollbar from 'rollbar';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';

import { UserService } from '../user/user.service';
import { EquipmentService } from '../equipment/equipment.service';
import { FilenameService } from '../filename/filename.service';
import { SessionsService } from '../sessions/sessions.service';
import { RecordingService } from '../recording/recording.service';
import { VideoOffService } from '../video-off/video-off.service';
import { MuteService } from '../mute/mute.service';
import { DolbyService } from '../dolby/dolby.service';
import { RollbarService } from '../../services/rollbar/rollbar.service';
import { AnalyticsService } from '../analytics/analytics.service';

import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';

import { Locations, RecordingDTO, RecordingInfo, RecordingIssue } from '@sc/types';
import { UserModel } from '@sc/types';
import { WalletService } from '../wallet/wallet.service';
import { StatsType } from '@sc/types';
import { StatsService } from '../stats/stats.service';
import { StorageService } from '../storage/storage.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class VideoRecorderService {
  session$ = this.sessionsService.studioSession$;
  constraints$ = this.equipmentService.constraints$;
  plan$ = this.walletService.studioPlan$;

  TIMESLICE = 4 * 1000;
  newRecordingBucket = this.storageService.recordingBucket;

  user: UserModel;
  recorder: MediaRecorder;
  cameraStream: MediaStream;
  filename: string;
  videoID: string;
  videoOff = false;
  mute = false;
  chunkCounter = 0;
  useNewBucket = false;
  deviceInfo: DeviceInfo;
  firstChunkUploaded = false;
  firstChunkTimeout: ReturnType<typeof setTimeout> = null;
  recordingInfo: Record<string, RecordingInfo> = {};
  recordingUpdate$ = new BehaviorSubject(null);
  warnings$ = new BehaviorSubject<Set<string>>(new Set());

  constructor(
    @Inject(RollbarService) private rollbar: Rollbar,
    private storage: Storage,
    private analyticsService: AnalyticsService,
    private deviceDetectorService: DeviceDetectorService,
    private dolbyService: DolbyService,
    private equipmentService: EquipmentService,
    private filenameService: FilenameService,
    private muteService: MuteService,
    private recordingService: RecordingService,
    private sessionsService: SessionsService,
    private statsService: StatsService,
    private storageService: StorageService,
    private toastrService: ToastrService,
    private userService: UserService,
    private videoOffService: VideoOffService,
    private walletService: WalletService
  ) {
    this.deviceInfo = this.deviceDetectorService.getDeviceInfo();
    this.setupUser();
    this.setupVideoOff();
    this.setupMute();
    this.setupFilename();
    this.setupRefs();
    this.setupRecordingStart();
    this.setupCameraError();
    this.newRecordingBucket.maxUploadRetryTime = 60 * 1000;
    this.storage.maxUploadRetryTime = 60 * 1000;
  }

  setupRecordingStart() {
    combineLatest([
      this.recordingService.recording$,
      this.dolbyService.conversation$,
      this.dolbyService.location$,
    ]).subscribe(([recording, convo, location]) => {
      if (!convo?.participants?.size) {
        this.stop();
        return;
      }
      const participantIsOnStage =
        location !== Locations.BACKSTAGE && !!Array.from(convo?.participants?.values()).find((p) => p.type === 'user');
      if (convo && recording && participantIsOnStage) this.start();
      else this.stop();
    });
  }

  async start() {
    if (this.recorder?.state === 'recording' || !this.plan$.value.videoRecording || !this.session$.value.videoEnabled)
      return;

    try {
      this.warnings$.next(new Set());

      if (this.firstChunkTimeout) {
        clearTimeout(this.firstChunkTimeout);
        this.firstChunkTimeout = null;
      }

      if (!this.constraints$.value.video) {
        this.recordingService.setRecording(this.videoID, { noCamera: true });
        return;
      }

      await this.setupRecorder();
      const recordingID = this.videoID;

      this.recordingService.videoReady$.next(true);
      await this.recordingService.audioReady$.nextExistingValue((v) => v === true);
      if (this.recordingInfo[recordingID].stopped) return;
      this.recorder.start(this.TIMESLICE);
      await this.analyticsService.track('started recording video', {
        sessionID: this.session$.value.sessionID,
        showID: this.session$.value.showID,
        castMemberID: this.user.uid,
        recordingID: this.videoID,
        camera: this.equipmentService.selectedCamera$.value?.label,
        microphone: this.equipmentService.selectedMicrophone$.value?.label,
        resolution: this.equipmentService.selectedResolution$.value,
      });
    } catch (err) {
      this.recordingService.videoReady$.next(false);
      if (this.cameraStream) this.cameraStream.getTracks().forEach((track) => track.stop());
      return;
    }

    this.recordingService.videoReady$.next(false);
  }

  stop() {
    if (this.recordingInfo[this.videoID]) this.recordingInfo[this.videoID].stopped = true;
    if (!this.recorder || this.recorder.state === 'inactive') {
      return;
    }

    this.recorder.stop();
    this.cameraStream.getTracks().forEach((track) => track.stop());
    this.warnings$.next(new Set());
  }

  setupUser() {
    this.userService.activeUser$.subscribe((user) => {
      this.user = user;
    });
  }

  setupMute() {
    this.muteService.mute$.subscribe((mute) => {
      this.mute = mute;

      if (this.recorder?.state === 'recording') {
        this.recorder.stream.getAudioTracks()[0].enabled = !this.mute;
      }
    });
  }

  setupVideoOff() {
    this.videoOffService.videoOff$.subscribe((videoOff) => {
      this.videoOff = videoOff;

      if (this.recorder?.state === 'recording') {
        this.recorder.stream.getVideoTracks()[0].enabled = !this.videoOff;
      }
    });
  }

  async setupRecorder() {
    await this.recordingService.localRecordingID$.nextExistingValue((id: string) => !this.recordingInfo[id]);
    this.chunkCounter = 0;
    this.firstChunkUploaded = false;
    const recordingID = this.videoID;
    this.recordingInfo[recordingID] = {
      sessionID: this.session$.value.sessionID,
      showID: this.session$.value.showID,
      filename: this.filename,
      take: this.session$.value.take,
      queue: new Map(),
      stats: { video: { ...this.statsService.defaultRecordingStats } },
      lastBytesTransferred: 0,
    };
    this.statsService.updateRecordingStats(
      recordingID,
      StatsType.VIDEO,
      this.recordingInfo[recordingID].stats.video,
      this.recordingInfo[recordingID].sessionID
    );
    this.recordingUpdate$.next(recordingID);

    try {
      const constraints = { ...this.constraints$.value };
      if (this.dolbyService.conferenceParams.dolbyVoice) constraints.audio.echoCancellation = true;
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      const streamSettings = stream.getVideoTracks()[0].getSettings();
      const resLimit = this.equipmentService.videoResolutions.WXGA;
      this.useNewBucket = streamSettings.width > resLimit.width || streamSettings.height > resLimit.width;
      // this.useNewBucket = true; // Can make all recordings go to new bucket

      const videoSettings: RecordingDTO = {
        videoHeight: streamSettings.height,
        videoWidth: streamSettings.width,
      };

      if (this.useNewBucket) {
        videoSettings.bucket = environment.firebase.recordingBucket;
        this.recordingInfo[recordingID].newBucket = true;
      }

      this.recordingService.setRecording(this.videoID, videoSettings);
      this.cameraStream = stream;
    } catch (e) {
      this.throwWarning(RecordingIssue.GET_USER_MEDIA_FAILED);
      this.rollbar.error('Video getUserMedia failed', e, this.recordingInfo);
      this.toastrService.warning(
        `Failed to access your camera.  You may need to reload the page.`,
        'Video Recorder Problem',
        {
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
          tapToDismiss: false,
          timeOut: 30 * 1000,
          toastComponent: GeneralToastComponent,
        }
      );
      return;
    }

    try {
      this.recorder = this.equipmentService.getVideoRecorder(this.cameraStream);
    } catch (e) {
      if (this.cameraStream) this.cameraStream.getTracks().forEach((track) => track.stop());
      this.throwWarning(RecordingIssue.GET_MEDIA_RECORDER_FAILED);
      this.rollbar.error('Video MediaRecorder failed', e, this.recordingInfo);
      this.toastrService.warning(
        `Failed to create an video recorder.  You may need to reload the page.`,
        'Video Recorder Problem',
        {
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
          tapToDismiss: false,
          timeOut: 30 * 1000,
          toastComponent: GeneralToastComponent,
        }
      );
      return;
    }

    this.recordingInfo[recordingID].stats.video.trackSettings = {
      audio: this.recorder.stream.getAudioTracks()[0].getSettings(),
      video: this.recorder.stream.getVideoTracks()[0].getSettings(),
    };

    // Chrome likes this here, Safari doesn't
    if (this.recorder.mimeType !== 'video/mp4') {
      this.recorder.stream.getVideoTracks()[0].enabled = !this.videoOff;
      this.recorder.stream.getAudioTracks()[0].enabled = !this.mute;
    }
    this.recorder.onstart = this.handleStart.bind(this);
    this.recorder.ondataavailable = this.handleRecording.bind(this);
    this.recorder.onerror = this.handleError.bind(this);
  }

  setupRefs() {
    this.recordingService.localRecordingID$.subscribe((localRecordingID: string) => {
      if (!this.plan$.value.videoRecording || !this.session$.value.videoEnabled) {
        this.recordingInfo[localRecordingID] = {};
        return;
      }
      this.videoID = localRecordingID;
      this.recordingService.setRecording(this.videoID, { hadVideoPlanAtRecording: true });
    });
  }

  setupFilename() {
    this.filenameService.fileName$.subscribe((filename) => {
      if (!filename || !this.plan$.value.videoRecording || !this.session$.value.videoEnabled) {
        return;
      }
      if (filename) this.filename = filename;
    });
  }

  handleStart() {
    this.recorder.stream.getVideoTracks()[0].enabled = !this.videoOff;
    this.recorder.stream.getAudioTracks()[0].enabled = !this.mute;
  }

  async readyToUpload(recordingID: string, num: number) {
    await firstValueFrom(
      this.recordingUpdate$.pipe(
        skipWhile(() => {
          return this.recordingInfo[recordingID].queue.has(num - 1);
        })
      )
    );
    return true;
  }

  async handleRecording(recording: { data: Blob }, retryChunk = 0) {
    if (typeof recording.data === 'undefined' || recording.data.size === 0) {
      return;
    }
    if (!retryChunk) this.chunkCounter = this.chunkCounter + 1;
    const chunkNum = retryChunk || this.chunkCounter;
    const recordingID = this.videoID;
    const recRef = this.recordingInfo[recordingID];
    const filename = recRef.filename;
    const blob = recording.data;

    if (!retryChunk) {
      if (chunkNum === 1) this.setFirstChunkTimeout();
      this.statsService.statsNewChunk(recRef, recordingID, StatsType.VIDEO, chunkNum, blob);
      await this.readyToUpload(recordingID, chunkNum);
    }

    let chunkRef;
    if (this.useNewBucket) {
      chunkRef = ref(this.newRecordingBucket, `${recRef.sessionID}/${recordingID}/${filename}`);
    } else {
      chunkRef = ref(this.storage, `${recRef.showID}/${recRef.sessionID}/${filename}`);
    }
    let fileRef: StorageReference;
    let chunkUpload: UploadTask;
    const t0 = performance.now();

    if (this.recorder.mimeType.includes('webm')) {
      fileRef = ref(chunkRef, `${chunkNum}.webm`);
      chunkUpload = uploadBytesResumable(fileRef, blob, { contentType: 'video/webm' });
    } else {
      fileRef = ref(chunkRef, `${chunkNum}.mp4`);
      chunkUpload = uploadBytesResumable(fileRef, blob, { contentType: 'video/mp4' });
    }

    if (!retryChunk) this.statsService.statsStartUpload(recRef, StatsType.VIDEO, chunkNum, blob);

    chunkUpload.on(
      'state_changed',
      (snapshot) => {
        this.statsService.statsChunkProgress(
          recRef,
          recordingID,
          StatsType.VIDEO,
          chunkNum,
          snapshot,
          this.recordingUpdate$
        );
      },
      (error: Error) => {
        this.statsService.statsChunkError(recRef, recordingID, StatsType.VIDEO, chunkNum, this.recordingUpdate$);
        this.rollbar.warn('Video Chunk Upload Error', { error, recordingInfo: this.recordingInfo });
        this.analyticsService.track('errored uploading video', {
          showID: recRef.showID,
          sessionID: recRef.sessionID,
          castMemberID: this.user.uid,
          recordingID,
          video: true,
          fileName: filename,
          error: error.message,
        });

        this.toastrService.warning(
          'There is a problem uploading the video files.  We will continue to retry the upload.  Please double check your network connection.',
          'Video Upload Stalled',
          {
            closeButton: true,
            tapToDismiss: false,
            timeOut: 20 * 1000,
            toastComponent: GeneralToastComponent,
          }
        );
        this.handleRecording({ data: blob }, chunkNum);
      },
      () => {
        this.statsService.statsChunkComplete(recRef, recordingID, StatsType.VIDEO, chunkNum, t0, this.recordingUpdate$);
        if (chunkNum === 1) {
          this.firstChunkUploaded = true;
        }
      }
    );
  }

  setFirstChunkTimeout() {
    this.firstChunkTimeout = setTimeout(() => {
      if (!this.firstChunkUploaded) {
        this.throwWarning(RecordingIssue.FIRST_CHUNK_UPLOAD_DELAYED);
        this.rollbar.error('First Video Chunk Timeout', this.recordingInfo);
        const warnToast: ActiveToast<GeneralToastComponent> = this.toastrService.warning(
          `The video upload is having a problem.  You may need to reload the page.`,
          'Video Upload Problem',
          {
            closeButton: true,
            tapToDismiss: false,
            timeOut: 0,
            toastComponent: GeneralToastComponent,
          }
        );
        warnToast.toastRef.componentInstance.buttons = [
          {
            label: 'Reload',
            handler: () => {
              window.location.reload();
            },
          },
        ];
        this.analyticsService.track('timed out uploading first video chunk', {
          showID: this.session$.value.showID,
          sessionID: this.session$.value.sessionID,
          castMemberID: this.user.uid,
          recordingID: this.videoID,
          video: true,
          fileName: this.filename,
        });
      }
    }, 60000);
  }

  setupCameraError() {
    this.equipmentService.cameraError$.subscribe(() => {
      if (this.recordingService.recording$.value) {
        this.throwWarning(RecordingIssue.CAMERA_DISCONNECTED);
        this.toastrService.error(
          'Camera disconnected while recording.  You will need to restart the recording.',
          'Camera Disconnected',
          {
            progressBar: true,
            closeButton: true,
            toastComponent: GeneralToastComponent,
          }
        );
      }
    });
  }

  throwWarning(msg: RecordingIssue) {
    this.warnings$.next(this.warnings$.value.add(msg));
    this.recordingService.setRecording(this.videoID, {
      warnings: arrayUnion(msg),
    });
  }

  handleError(error: Error) {
    this.rollbar.error('Video recorder error: ', error, this.recordingInfo);
    const warnToast: ActiveToast<GeneralToastComponent> = this.toastrService.warning(
      `The video recording has run into a problem.  You may need to reload the page.`,
      'Video Recorder Problem',
      {
        closeButton: true,
        tapToDismiss: false,
        timeOut: 0,
        toastComponent: GeneralToastComponent,
      }
    );

    warnToast.toastRef.componentInstance.buttons = [
      {
        label: 'Reload',
        handler: () => {
          window.location.reload();
        },
      },
    ];

    this.analyticsService.track('errored while recording video', {
      sessionID: this.session$.value.sessionID,
      castMemberID: this.user.uid,
      recordingID: this.videoID,
      video: true,
      fileName: this.filename,
    });
  }
}
