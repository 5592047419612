import { Injectable } from '@angular/core';
import { Firestore, collection, doc, docData, setDoc } from '@angular/fire/firestore';
import { map } from 'rxjs';
import { Participant } from '@sc/types';

@Injectable({
  providedIn: 'root',
})
export class FileCountService {
  sessionsCol = collection(this.firestore, 'sessions');
  constructor(private firestore: Firestore) {}

  setFileCount(sessionID: string, userID: string, recordingCount: number) {
    return setDoc(doc(this.sessionsCol, sessionID, 'participants', userID), { recordingCount }, { merge: true });
  }

  getFileCount(sessionID: string, userID: string) {
    return docData<Participant>(doc(this.sessionsCol, sessionID, 'participants', userID)).pipe(
      map((participant) => participant?.recordingCount || 0)
    );
  }
}
