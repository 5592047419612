import { Statistics } from '@voxeet/voxeet-web-sdk/types/models/Statistics';
import { Timestamp, FieldValue } from '@angular/fire/firestore';
import { ActiveToast } from 'ngx-toastr';
import { DeviceInfo } from 'ngx-device-detector';
import { UploadTask } from '@angular/fire/storage';

export interface Recording {
  migrated?: boolean;
  uid?: string;
  idToken?: string;
  orgID?: string;
  showID?: string;
  showOwner?: string;
  sessionID?: string;
  castMemberID?: string;
  recordingID?: string;
  participantID?: string;
  fileName?: string;
  renderVideo?: boolean;
  castMemberName?: string;
  datetime?: Timestamp;
  deviceInfo?: DeviceInfo;
  count?: string;
  percentage?: number;
  videoPercentage?: number;
  noCamera?: boolean;
  hadVideoPlanAtRecording?: boolean;
  screenRecording?: boolean;
  wav?: string;
  mp3?: string;
  mp4?: string;
  webm?: string;
  isChecked?: boolean;
  key?: string;
  iOS?: boolean;
  preview?: boolean;
  take?: number;
  takeName?: string;
  takeID?: string;
  audioPlaybackVisible?: boolean;
  videoPlaybackVisible?: boolean;
  videoHeight?: number;
  videoWidth?: number;
  enhanced?: boolean;
  error?: any;
  renderFailed?: boolean;
  uri?: string;
  stats?: UploadStats;
  warnings?: string[];
  newCodec?: boolean;
  bucket?: boolean;
  rawURL?: string;
  rawBktPath?: string;
  mixed?: boolean;
}

export interface CloudRecordingClassic {
  conferenceID: string;
  duration: number;
  fileType: 'video/mp4' | 'audio/mpeg';
  filename: string;
  region: string;
  timestamp: Timestamp;
  url: string;
  recordingType?: 'mix' | 'participant';
  participant?: CloudRecordingClassicParticipant;
  take?: number;
}

export interface CloudRecordingClassicParticipant {
  userId: string;
  trackType: string;
  trackIndex: number;
  metadata: {
    externalId: string;
    externalName: string;
    externalPhotoUrl: string;
  };
}
export interface CloudRecordingDolby {
  conferenceID: string;
  duration: number;
  fileType: 'audio/mpeg';
  filename: string;
  memberIDs: string[];
  records: {
    take?: number;

    duration: number;
    filename: string;
    size: number;
    splits: {
      duration: number;
      filename: string;
      metadata: object & {
        externalId: string;
        externalName?: string;
        externalPhotoUrl: string;
      };
      size: number;
      startTime: number;
      url: string;
      userId: string;
    }[];
    startTime: number;
    url: string;
  }[];
  region: string;
  size: number;
  timestamp: Timestamp;
  url: string;
  take?: number;
}

export interface RecordingDTO {
  fileName?: string;
  take?: number;
  takeID?: string;
  participantID?: string;
  orgID?: string;
  showID?: string;
  sessionID?: string;
  datetime?: FieldValue;
  iOS?: boolean;
  screenRecording?: boolean;
  hadVideoPlanAtRecording?: boolean;
  noCamera?: boolean;
  stats?: UploadStats;
  warnings?: FieldValue;
  newCodec?: boolean;
  deviceInfo?: DeviceInfo;
  preview?: boolean;
  videoHeight?: number;
  videoWidth?: number;
  bucket?: string;
}

export interface RecordingInfo {
  sessionID?: string;
  showID?: string;
  showOwner?: string;
  orgID?: string;
  videoEnabled?: boolean;
  filename?: string;
  take?: number;
  queue?: Map<number, number>;
  stats?: UploadStats;
  awaitingProcessing?: boolean;
  stickAroundToast?: ActiveToast<any>;
  stopped?: boolean;
  lastBytesTransferred?: number;
  newBucket?: boolean;
}

export interface UploadChunk {
  progress: number;
  upload?: UploadTask;
}

export interface UploadStats {
  audio?: RecordingStats;
  video?: RecordingStats;
  screen?: RecordingStats;
  rtcStats?: Record<number, Statistics[]>;
}

export interface RecordingStats {
  chunkMap?: {
    [key: number]: ChunkStats;
  };
  chunkQueueSize?: number;
  totalChunks?: number;
  bytesRecorded?: number;
  bytesTransferred?: number;
  timestamp?: number;
  trackSettings?: TrackSettings;
}

export interface TrackSettings {
  audio?: MediaTrackSettings & { latency?: string };
  video?: MediaTrackSettings & { resizeMode?: string };
}

export interface ChunkStats {
  timestamp: number;
  size: number;
  uploadTime?: number;
  uploadStart?: number;
}

export enum VideoResolutions {
  UHD = 'UHD', // 4k
  FHD = 'FHD', // 1080p
  WXGA = 'WXGA', // 720p
}

export enum VideoResolutionsForDisplay {
  UHD = '4K [Beta]',
  FHD = '1080p',
  WXGA = '720p',
}

export enum RecordingIssue {
  RECORDING_START_DELAYED = 'Recording Start Delayed',
  RECORDING_START_FAILED = 'Recording Start Failed',

  FIRESTORE_CONNECTION_FAILED = 'Firestore Connection Failed',

  RECORDER_ERRORED = 'Recorder Errored',

  FIRST_CHUNK_UPLOAD_DELAYED = 'First Chunk Upload Delayed',
  CHUNK_UPLOAD_FAILED = 'Chunk Upload Failed',

  GET_USER_MEDIA_FAILED = 'Recorder Get User Media Failed',
  GET_MEDIA_RECORDER_FAILED = 'Recorder Get Media Recorder Failed',

  DATA_UPLOAD_FAILED = 'Data Upload Failed',
  DATA_UPLOAD_SLOW = 'Data Upload Slow',

  MICROPHONE_DISCONNECTED = 'Microphone Disconnected',
  CAMERA_DISCONNECTED = 'Camera Disconnected',
}
