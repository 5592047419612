import { Injectable } from '@angular/core';
import { Firestore, CollectionReference, collection, doc, docData, setDoc } from '@angular/fire/firestore';
import { SCSubject } from '../../util/sc-subject.class';
import { UserService } from '../user/user.service';
import { Observable, of, switchMap } from 'rxjs';
import {
  AppSettings,
  ConfTypes,
  EnhanceSettings,
  MixSettings,
  SettingsCollections,
  ShowSettings,
  Themes,
  UserOrgSettings,
} from '@sc/types';
import { ShowsService } from '../shows/shows.service';
import { SessionsService } from '../sessions/sessions.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  public userAppSettings$ = new SCSubject<AppSettings>();

  public studioShowSettings$ = new SCSubject<ShowSettings>();
  public dashboardShowSettings$ = new SCSubject<ShowSettings>();

  public dashboardShowEnhanceSettings$ = new SCSubject<EnhanceSettings>();
  public dashboardShowMixSettings$ = new SCSubject<MixSettings>();

  private activeUser$ = this.userService.activeUser$;
  private studioShow$ = this.sessionsService.studioShow$;
  private dashboardShow$ = this.showsService.dashboardShow$;
  private scCol: CollectionReference = collection(this.firestore, 'SquadCasters');
  private showsCol: CollectionReference = collection(this.firestore, 'shows');

  constructor(
    private showsService: ShowsService,
    private userService: UserService,
    private firestore: Firestore,
    private sessionsService: SessionsService
  ) {
    this.setupUserSettings();
  }

  setupUserSettings() {
    this.setupAppSettings();
    this.setupShowSettings();
    this.setupEnhanceSettings();
    this.setupMixSettings();
  }

  /**
   * Setups and listens for changes in the user's app settings collection
   */
  setupAppSettings() {
    this.activeUser$
      .pipe(
        switchMap((user) => {
          return this.getAppSettings(user.uid);
        })
      )
      .subscribe((settings) => {
        // Can remove muteOnJoin check after dev wipe
        if (settings && settings.muteOnJoin !== undefined) this.userAppSettings$.next(settings);
        else this.setDefaultAppSettings(this.activeUser$.value.uid);
      });
  }

  /**
   * Setups and listens for changes in the active shows settings
   */
  setupShowSettings() {
    let showID: string;
    this.studioShow$
      .pipe(
        switchMap((show) => {
          if (!show) {
            showID = null;
            return of(null);
          }
          showID = show.showID;
          return this.getShowSettings(show.showID);
        })
      )
      .subscribe((settings) => {
        if (settings) this.studioShowSettings$.next(settings);
        else if (showID) this.setDefaultShowSettings(showID);
      });

    this.dashboardShow$
      .pipe(
        switchMap((show) => {
          if (!show) {
            showID = null;
            return of(null);
          }
          showID = show.showID;
          return this.getShowSettings(show.showID);
        })
      )
      .subscribe((settings) => {
        if (settings) this.dashboardShowSettings$.next(settings);
        else if (showID) this.setDefaultShowSettings(showID);
      });
  }

  /**
   * Setups and listens for changes in the user's app settings collection
   */
  setupEnhanceSettings() {
    let showID: string;
    this.showsService.dashboardShow$
      .pipe(
        switchMap((show) => {
          if (!show) {
            showID = null;
            return of(null);
          }
          showID = show.showID;
          return this.getEnhanceSettings(showID);
        })
      )
      .subscribe((settings) => {
        if (settings) this.dashboardShowEnhanceSettings$.next(settings);
        else if (showID) this.setDefaultEnhance(showID);
      });
  }

  /**
   * Setups and listens for changes in the user's app settings collection
   */
  setupMixSettings() {
    let showID: string;
    this.dashboardShow$
      .pipe(
        switchMap((show) => {
          if (!show) {
            showID = null;
            return of(null);
          }
          showID = show.showID;
          return this.getMixSettings(showID);
        })
      )
      .subscribe((settings) => {
        if (settings) this.dashboardShowMixSettings$.next(settings);
        else if (showID) this.setDefaultAuphonic(showID);
      });
  }

  /**
   * Returns the snapshot changes for the user's app settings
   *
   * @param uid : User ID
   * @returns Observable<AppSettings>
   */
  getAppSettings(uid?: string): Observable<AppSettings> {
    if (!uid) uid = this.activeUser$.value.uid;
    return docData<AppSettings>(doc(this.scCol, `${uid}/settings/${SettingsCollections.APP}`));
  }

  /**
   * Returns the snapshot changes for the user's org settings
   *
   * @param uid : User ID
   * @returns Observable<UserOrgSettings>
   */
  getUserOrgSettings(uid?: string, orgID?: string): Observable<UserOrgSettings> {
    if (!uid) uid = this.activeUser$.value.uid;
    if (!orgID) orgID = this.activeUser$.value.activeOrg;
    if (!orgID) return of(null);
    return docData<UserOrgSettings>(doc(this.scCol, `${uid}/settings/${orgID}`));
  }

  /**
   * Returns the snapshot changes for the show's enhance settings
   *
   * @param showID : Show ID
   * @returns Observable<EnhanceSettings>
   */
  getEnhanceSettings(showID: string) {
    return docData<EnhanceSettings>(doc(this.showsCol, `${showID}/settings/${SettingsCollections.ENHANCE_SETTINGS}`));
  }

  /**
   * Returns the snapshot changes for the show's mix settings
   *
   * @param showID : Show ID
   * @returns Observable<MixSettings>
   */
  getMixSettings(showID: string) {
    return docData<MixSettings>(doc(this.showsCol, `${showID}/settings/${SettingsCollections.MIX_SETTINGS}`));
  }

  /**
   * Returns the snapshot changes for the show's settings
   *
   * @param showID : Show ID
   * @returns Observable<MixSettings>
   */
  getShowSettings(showID: string) {
    return docData<ShowSettings>(doc(this.showsCol, `${showID}/settings/${SettingsCollections.SHOW_SETTINGS}`));
  }

  /**
   * Sets the user's APP Settings for a given settings object
   *
   * @param settings : AppSettings
   * @returns Promise<void>
   */
  setUserAppSettings(settings: AppSettings) {
    if (this.activeUser$.value.guest) return;
    const uid = this.activeUser$.value.uid;
    return setDoc(doc(this.scCol, `${uid}/settings/${SettingsCollections.APP}`), settings, { merge: true });
  }

  /**
   * Sets the user's organization Settings for a given organization
   *
   * @param settings : UserOrgSettings
   * @returns Promise<void>
   */
  setUserOrgSettings(orgID: string, settings: UserOrgSettings) {
    const uid = this.activeUser$.value.uid;
    return setDoc(doc(this.scCol, `${uid}/settings/${orgID}`), settings, { merge: true });
  }

  /**
   * Sets the user's ENHANCE Settings for a given settings object
   *
   * @param settings : EnhanceSettings
   * @returns Promise<void>
   */
  setShowEnhanceSettings(settings: EnhanceSettings, showID: string) {
    return setDoc(doc(this.showsCol, `${showID}/settings/${SettingsCollections.ENHANCE_SETTINGS}`), settings, {
      merge: true,
    });
  }

  /**
   * Sets the user's MIX_SETTINGS Settings for a given settings object
   *
   * @param settings : EnhanceSettings
   * @returns Promise<void>
   */
  setShowMixSettings(settings: MixSettings, showID: string) {
    return setDoc(doc(this.showsCol, `${showID}/settings/${SettingsCollections.MIX_SETTINGS}`), settings, {
      merge: true,
    });
  }

  /**
   * Sets the user's Show Settings for a given settings object
   *
   * @param settings : ShowSettings
   * @returns Promise<void>
   */
  setShowSettings(settings: ShowSettings, showID: string) {
    return setDoc(doc(this.showsCol, `${showID}/settings/${SettingsCollections.SHOW_SETTINGS}`), settings, {
      merge: true,
    });
  }

  /**
   * Sets the user's Default App Settings
   *
   * @param userID : User ID
   * @returns Promise<void>
   */
  setDefaultAppSettings(userID: string) {
    if (this.activeUser$.value?.guest || this.userService.deletingUser$.value) return;
    return setDoc(
      doc(this.scCol, `${userID}/settings/${SettingsCollections.APP}`),
      {
        muteOnJoin: false,
        askFeedback: true,
        theme: Themes.LIGHT,
        allowMultipleSidebars: true,
      },
      { merge: true }
    );
  }

  /**
   * Sets the Default Show Settings
   *
   * @param showID : Show ID
   * @returns Promise<void>
   */
  async setDefaultShowSettings(showID: string) {
    if (this.activeUser$.value?.guest) return;
    await this.setDefaultEnhance(showID);
    await this.setDefaultAuphonic(showID);
    return setDoc(
      doc(this.showsCol, `${showID}/settings/${SettingsCollections.SHOW_SETTINGS}`),
      {
        fullHD: false,
        defaultConfType: ConfTypes.CLASSIC,
      },
      { merge: true }
    );
  }

  /**
   * Sets theDefault Dolby Mastering Settings
   *
   * @param showID : Show ID
   * @returns Promise<void>
   */
  setDefaultEnhance(showID: string) {
    if (this.activeUser$.value?.guest) return;
    const audioSettings: EnhanceSettings = {
      loudness: {
        enable: true,
        target_level: -16,
        dialog_intelligence: true,
        speech_threshold: 15,
        peak_limit: -1,
        peak_reference: 'true_peak',
      },
      dynamics: {
        range_control: {
          enable: true,
          amount: 'medium',
        },
      },
      noise: {
        reduction: {
          enable: true,
          amount: 'medium',
        },
      },
      filter: {
        high_pass: {
          enable: true,
          frequency: 80,
        },
        dynamic_eq: {
          enable: true,
        },
        hum: {
          enable: true,
        },
      },
      speech: {
        isolation: {
          enable: true,
          amount: 0,
        },
        sibilance: {
          reduction: {
            enable: true,
            amount: 'medium',
          },
        },
        plosive: {
          reduction: {
            enable: true,
            amount: 'medium',
          },
        },
        click: {
          reduction: {
            enable: true,
            amount: 'medium',
          },
        },
      },
      music: {
        detection: {
          enable: false,
        },
      },
    };

    return setDoc(doc(this.showsCol, `${showID}/settings/${SettingsCollections.ENHANCE_SETTINGS}`), audioSettings);
  }

  /**
   * Sets the Default Auphonic Mix Settings
   *
   * @param showID : Show ID
   * @returns Promise<void>
   */
  setDefaultAuphonic(showID: string) {
    if (this.activeUser$.value?.guest) return;
    const mixingSettings = {
      individual: {
        hipfilter: true,
        denoise: false,
      },
      master: {
        loudnesstarget: -16,
        leveler: true,
        gate: true,
        crossgate: true,
      },
    };

    return setDoc(doc(this.showsCol, `${showID}/settings/${SettingsCollections.MIX_SETTINGS}`), mixingSettings);
  }
}
