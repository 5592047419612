<ion-row id="container" class="ion-no-padding">
  <ion-button aria-label="Cancel" (click)="close()" tooltip="Cancel" class="leave-button">
    <ion-icon src="/assets/icons/24px/close.svg" slot="icon-only"></ion-icon>
  </ion-button>
  <ion-col size-xs="12" size-sm="12" size-md="7" class="left ion-no-padding">
    <h2 class="bold ion-no-margin confirm-header">Confirm Subscription</h2>

    <ion-row>
      <ion-col class="ion-align-items-center">
        <h6 class="bold">Billing Cycle</h6>

        <ion-radio-group [(ngModel)]="billingInterval">
          <ion-row>
            <ion-col>
              <ion-list *ngIf="monthlyPlan" lines="none">
                <ion-item class="billing-cycle">
                  <ion-radio
                    (ionFocus)="billingIntervalSelected('Monthly')"
                    (ionFocus)="checkCoupon()"
                    [disabled]="confirmingSubscription"
                    mode="md"
                    value="Monthly"
                    aria-label="Monthly Billing">
                  </ion-radio>

                  <div class="ion-text-wrap">
                    <h6 class="medium ion-no-margin">Monthly</h6>

                    <h2 class="bold ion-no-margin">
                      ${{ monthlyPlan.price }}

                      <span class="action-medium"> /Month </span>
                    </h2>

                    <p class="body-regular ion-no-margin">More flexible</p>
                  </div>
                </ion-item>
              </ion-list>
            </ion-col>

            <ion-col>
              <ion-list id="billing-cycle-list" lines="none">
                <ion-item *ngIf="yearlyPlan" class="billing-cycle">
                  <ion-radio
                    (ionFocus)="billingIntervalSelected('Yearly')"
                    (ionFocus)="checkCoupon()"
                    [disabled]="confirmingSubscription || !yearlyPlan.price"
                    mode="md"
                    value="Yearly"
                    aria-label="Yearly Billing">
                  </ion-radio>

                  <div class="ion-text-wrap">
                    <h6 class="medium ion-no-margin">Yearly</h6>

                    <h2 *ngIf="yearlyPlan.price" class="bold ion-no-margin">
                      ${{ yearlyPlan.price }}

                      <span class="action-medium"> /Year </span>
                    </h2>

                    <h2 *ngIf="!yearlyPlan.price" class="bold ion-no-margin">Unavailable</h2>

                    <p *ngIf="yearlyPlan.price" class="body-regular ion-no-margin">More savings</p>

                    <p *ngIf="!yearlyPlan.price" class="body-regular ion-no-margin">Try Monthly</p>
                  </div>

                  <ion-badge *ngIf="yearlyPlan.discount" class="discount">Save ${{ yearlyPlan.discount }}</ion-badge>
                </ion-item>
              </ion-list>
            </ion-col>
          </ion-row>
        </ion-radio-group>

        <h6 class="bold">Payment Info</h6>

        <ion-row>
          <ion-col class="ion-no-padding">
            <sc-payment-info #paymentInfo> </sc-payment-info>
          </ion-col>
        </ion-row>

        <p class="ion-text-center stripe body-small">
          <ion-icon src="/assets/icons/24px/lock.svg" slot="start"> </ion-icon>
          <a
            tabindex="-1"
            aria-hidden="true"
            href="https://stripe.com/docs/security/stripe"
            alt="Stripe Security"
            target="_blank">
            Secured
          </a>
          by
          <a
            aria-label="Secured by Stripe"
            href="https://stripe.com/docs/security/stripe"
            alt="Stripe Security"
            target="_blank">
            Stripe
          </a>
          and
          <a
            href="https://policies.google.com/terms"
            alt="reCAPTCHA Terms of Service"
            target="_blank"
            aria-label="Secured by re captcha">
            reCAPTCHA
          </a>
        </p>
      </ion-col>
    </ion-row>
  </ion-col>

  <ion-col size-xs="12" size-sm="12" size-md="5" class="right ion-no-padding">
    <div class="right-padding">
      <!-- <ion-button aria-label="Cancel" (click)="close()" tooltip="Cancel" class="ion-hide-md-down leave-button">
        <ion-icon src="/assets/icons/24px/close.svg" slot="icon-only"> </ion-icon>
      </ion-button> -->

      <h4 class="bold ion-no-margin">Summary</h4>

      <ion-list lines="none">
        <ion-accordion-group>
          <ion-accordion value="planAccordion" toggle-icon="/assets/icons/24px/chevron-down.svg">
            <ion-item [disabled]="confirmingSubscription" slot="header" lines="none">
              <ion-label>
                <strong> {{ selectedPlan.name }} Plan </strong>
              </ion-label>
            </ion-item>

            <ion-list slot="content">
              <ion-item
                (click)="selectPlan(plan)"
                *ngFor="let plan of selectedPlan.interval === 'Monthly' ? allPlans.monthly : allPlans.yearly"
                lines="none"
                class="plan-item condensed">
                <ion-icon
                  [src]="
                    selectedPlan.id === plan.id
                      ? '/assets/icons/24px/radio-button-solid.svg'
                      : '/assets/icons/24px/radio-button-outline.svg'
                  "
                  [class.selected]="selectedPlan.id === plan.id"
                  slot="start"></ion-icon>

                <ion-label> {{ plan.name }} Plan </ion-label>
              </ion-item>
            </ion-list>
          </ion-accordion>
        </ion-accordion-group>

        <ion-accordion-group>
          <ion-accordion toggle-icon="/assets/icons/24px/chevron-down.svg">
            <ion-item slot="header" lines="none">
              <ion-label class="sr-only">
                Premium features include {{ selectedPlan.recordingHours }} hours of {{ selectedPlan.videoRecording ?
                'video' : 'audio' }} recording every month, {{ selectedPlan.shows }} show{{ selectedPlan.shows > 1 ||
                selectedPlan.shows === 'Unlimited' ? 's' : '' }}, {{ selectedPlan.seats }} team member seats, {{
                selectedPlan.inStudio }} in studio participants, {{ selectedPlan.backstage }} backstage participants,
                and much more! Price is {{ selectedPlan.price }} U.S. dollars {{ selectedPlan.interval }}.
              </ion-label>
              <ion-label> Premium Features </ion-label>
            </ion-item>

            <ion-list slot="content">
              <ion-item class="condensed" lines="none">
                <ion-icon src="/assets/icons/24px/check.svg" slot="start"></ion-icon>

                <ion-label class="ion-text-wrap">
                  <strong>{{ selectedPlan.recordingHours }} Hours</strong> of
                  <span *ngIf="!selectedPlan.videoRecording">Audio</span
                  ><span *ngIf="selectedPlan.videoRecording">Video</span> Recording
                </ion-label>
              </ion-item>

              <ion-item lines="none" class="condensed">
                <ion-icon src="/assets/icons/24px/check.svg" slot="start"></ion-icon>

                <ion-label>
                  <strong>{{ selectedPlan.shows }} Show<span *ngIf="selectedPlan.shows > 1">s</span></strong>
                </ion-label>
              </ion-item>

              <ion-item lines="none" class="condensed">
                <ion-icon src="/assets/icons/24px/check.svg" slot="start"></ion-icon>

                <ion-label> <strong>{{ selectedPlan.seats }} Team Member</strong> Seats </ion-label>
              </ion-item>

              <ion-item lines="none" class="condensed">
                <ion-icon src="/assets/icons/24px/check.svg" slot="start"></ion-icon>

                <ion-label> <strong>{{ selectedPlan.inStudio }} In Studio</strong> Participants </ion-label>
              </ion-item>

              <ion-item lines="none" class="condensed">
                <ion-icon src="/assets/icons/24px/check.svg" slot="start"></ion-icon>

                <ion-label> <strong>{{ selectedPlan.backstage }} Backstage</strong> Participants </ion-label>
              </ion-item>

              <ion-item lines="none" class="condensed">
                <ion-icon src="/assets/icons/24px/check.svg" slot="start"></ion-icon>

                <ion-label>
                  <strong
                    >{{ selectedPlan.integrations }} Integration<span *ngIf="selectedPlan.integrations > 1"
                      >s</span
                    ></strong
                  >
                  + Zapier
                </ion-label>
              </ion-item>

              <ion-item lines="none" class="condensed">
                <ion-icon src="/assets/icons/24px/check.svg" slot="start"></ion-icon>

                <ion-label> <strong>Audio</strong> Recording </ion-label>

                <ion-icon
                  src="/assets/icons/16px/info-outline.svg"
                  tooltip="48kHz @ 16-bit Local & Cloud"
                  slot="end"
                  class="grey"></ion-icon>
              </ion-item>

              <ion-item lines="none" class="condensed">
                <ion-icon
                  [src]="selectedPlan.videoRecording ? '/assets/icons/24px/check.svg' : '/assets/icons/24px/close.svg'"
                  [class.grey]="!selectedPlan.videoRecording"
                  slot="start"></ion-icon>

                <ion-label [class.strikeout]="!selectedPlan.videoRecording">
                  <strong>Video + Screen</strong> Recording
                </ion-label>

                <ion-icon
                  src="/assets/icons/16px/info-outline.svg"
                  tooltip="1080p @ 30fps Local & Cloud"
                  slot="end"
                  class="grey"></ion-icon>
              </ion-item>

              <ion-item lines="none" class="condensed">
                <ion-icon
                  [src]="selectedPlan.audioMixing ? '/assets/icons/24px/check.svg' : '/assets/icons/24px/close.svg'"
                  [class.grey]="!selectedPlan.videoRecording"
                  slot="start"></ion-icon>

                <ion-label [class.strikeout]="!selectedPlan.audioMixing">
                  <strong>Mix Audio</strong> with Auphonic
                </ion-label>
              </ion-item>

              <ion-item lines="none" class="condensed">
                <ion-icon
                  [src]="selectedPlan.dolbyVoice ? '/assets/icons/24px/check.svg' : '/assets/icons/24px/close.svg'"
                  [class.grey]="!selectedPlan.dolbyVoice"
                  slot="start"></ion-icon>

                <ion-label [class.strikeout]="!selectedPlan.dolbyVoice">
                  <strong>Dolby Voice</strong> Conferencing
                </ion-label>
              </ion-item>

              <ion-item lines="none" class="condensed">
                <ion-icon
                  [src]="selectedPlan.dolbyMastering ? '/assets/icons/24px/check.svg' : '/assets/icons/24px/close.svg'"
                  [class.grey]="!selectedPlan.dolbyMastering"
                  slot="start"></ion-icon>

                <ion-label [class.strikeout]="!selectedPlan.dolbyMastering">
                  <strong>Master Audio</strong> with Dolby
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-accordion>
        </ion-accordion-group>

        <h4 class="bold">Payment Details</h4>

        <ion-item lines="full">
          <ion-label class="ion-text-wrap"> Subtotal </ion-label>

          <ion-label slot="end" class="ion-text-wrap"> ${{ selectedPlan.price }} </ion-label>
        </ion-item>

        <ion-item lines="full" id="coupon-item">
          <ion-input
            id="coupon-input"
            [(ngModel)]="promoCode"
            (keyup.enter)="checkCoupon()"
            (ionBlur)="checkCoupon()"
            [disabled]="confirmingSubscription"
            placeholder="Coupon code"
            name="promoCode"
            type="text">
          </ion-input>

          <ion-button
            *ngIf="promoCode && promoCode.length && !percentOff && !amountOff && !unknownCoupon"
            (click)="checkCoupon()"
            class="line"
            slot="end">
            Apply
          </ion-button>

          <ion-chip slot="end" outline="true" *ngIf="percentOff" (click)="resetCoupon()" class="couponBadge">
            <span> {{ percentOff }}% off {{ couponDuration }} </span>

            <ion-icon (click)="resetCoupon()" src="/assets/icons/24px/close-circle.svg"></ion-icon>
          </ion-chip>

          <ion-chip slot="end" outline="true" *ngIf="amountOff" (click)="resetCoupon()" class="couponBadge">
            <span> ${{ amountOff }} off {{ couponDuration }} </span>

            <ion-icon (click)="resetCoupon()" src="/assets/icons/24px/close-circle.svg"></ion-icon>
          </ion-chip>

          <ion-chip
            slot="end"
            outline="true"
            *ngIf="!promoCode && unknownCoupon"
            (click)="resetCoupon()"
            class="couponBadge">
            <span> Unknown </span>

            <ion-icon (click)="resetCoupon()" src="/assets/icons/24px/close-circle.svg"></ion-icon>
          </ion-chip>
        </ion-item>

        <ion-item *ngIf="trialEndDate" lines="full">
          <ion-label class="ion-text-wrap">
            Total due on<br />
            {{ trialEndDate }}
          </ion-label>

          <ion-label slot="end" class="nowrap">
            ${{ discountedPrice !== null ? discountedPrice.toFixed(0) : selectedPlan.price.toFixed(0) }}
          </ion-label>
        </ion-item>

        <ion-item>
          <ion-label class="ion-text-wrap">
            <strong> Total today </strong>
          </ion-label>

          <ion-label slot="end" class="nowrap">
            <!-- add ngifs here for totals -->
            <strong *ngIf="page === 'upgrade'">
              ${{ primarySub ? proratedInfo?.dueToday ? (proratedInfo.amountDue / 100).toFixed(2) : '0.00' :
              selectedPlan.price.toFixed(2) }}
            </strong>

            <strong *ngIf="page === 'checkout'"> $0 </strong>
          </ion-label>
        </ion-item>

        <ion-item>
          <ion-label *ngIf="trialEndDate" class="ion-text-wrap">
            You will be charged ${{ discountedPrice !== null ? discountedPrice : selectedPlan.price }}.00 on {{
            trialEndDate }}, when your trial ends. You can cancel any time.
          </ion-label>
          <ion-label
            *ngIf="!trialEndDate && proratedInfo?.proratedAmount && !proratedInfo?.totalCredit"
            class="ion-text-wrap">
            You will be charged the difference of ${{ (proratedInfo.amountDue / 100).toFixed(2) }} {{
            proratedInfo.dueToday ? 'today' : 'on' }} {{ proratedInfo.dueToday ? '' : proratedInfo.nextBillDate }} and
            your hours will be prorated{{ !proratedInfo.dueToday ? ' today' : '' }}. Please check your email for a final
            invoice.
          </ion-label>

          <ion-label
            *ngIf="!trialEndDate && proratedInfo?.proratedAmount && proratedInfo?.totalCredit"
            class="ion-text-wrap">
            Your hours will be prorated today. Your account will be credited for ${{ proratedInfo.totalCredit }} and {{
            !proratedInfo.dueToday ? 'your billing cycle will remain the same.' : 'your billing cycle will be set to
            today.' }} Please check your email for a final invoice.
          </ion-label>
          <ion-label
            *ngIf="!trialEndDate && !proratedInfo?.proratedAmount && !proratedInfo?.totalCredit"
            class="ion-text-wrap">
            You will be charged ${{ selectedPlan.price.toFixed(2) }} {{ proratedInfo?.nextBillDate ? 'on your next
            billing date ' + proratedInfo.nextBillDate + '.' : 'today.' }} Please check your email for a final invoice.
          </ion-label>
        </ion-item>
      </ion-list>
    </div>

    <div id="confirmButtonContainer">
      <ion-button
        *ngIf="!confirmingSubscription"
        id="confirmButton"
        (click)="confirmSubscription()"
        [disabled]="!paymentInfo.billingEmail || (!paymentInfo.cardDetails && !paymentInfo.cardDetailsFilledOut)"
        class="primary"
        size="large"
        shape="round">
        Confirm Subscription
      </ion-button>

      <ion-progress-bar *ngIf="confirmingSubscription" type="indeterminate" color="primary"> </ion-progress-bar>
    </div>
  </ion-col>
</ion-row>
