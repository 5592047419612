import { Component, OnDestroy, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'sc-vu-meter',
  templateUrl: './vu-meter.component.html',
  styleUrls: ['./vu-meter.component.scss'],
})
export class VuMeterComponent implements OnChanges, OnDestroy {
  @Input() stream: MediaStream;
  @Output() level = new EventEmitter<number>();
  meeterLevel = 0;
  dBFS = 0;
  dBFSDisplayValue = '';
  dBFSVisible = false;
  audioContext: AudioContext;
  mediaStreamAudioSourceNode: MediaStreamAudioSourceNode;
  analyserNode: AnalyserNode;

  grabInterval: NodeJS.Timer;
  animationInterval: NodeJS.Timer;

  subs: Array<Subscription> = [];

  constructor() {
    this.audioContext = new AudioContext();
  }

  ngOnChanges(changes) {
    if (changes.stream.currentValue.id !== changes.stream.previousValue?.id) this.setupMeeter();
  }

  setupMeeter() {
    if (this.mediaStreamAudioSourceNode) this.mediaStreamAudioSourceNode.disconnect();
    if (this.analyserNode) this.analyserNode.disconnect();
    if (!this.stream.getAudioTracks().length) return;

    this.mediaStreamAudioSourceNode = this.audioContext.createMediaStreamSource(this.stream);
    this.analyserNode = this.audioContext.createAnalyser();
    this.mediaStreamAudioSourceNode.connect(this.analyserNode);

    const pcmData = new Float32Array(this.analyserNode.fftSize);

    clearInterval(this.grabInterval);
    clearInterval(this.animationInterval);

    this.grabInterval = setInterval(() => {
      const tracks = this.stream?.getAudioTracks();
      if (!tracks.length || tracks[0].readyState === 'ended') {
        clearInterval(this.grabInterval);
        clearInterval(this.animationInterval);
        setTimeout(() => {
          this.setupMeeter();
        }, 100);
      }

      this.analyserNode.getFloatTimeDomainData(pcmData);
      let sumSquares = 0.0;
      for (const amplitude of pcmData) {
        sumSquares += amplitude * amplitude;
      }
      if (!sumSquares) return;
      const volumeVal = Math.sqrt(sumSquares / pcmData.length);
      this.dBFS = 20 * Math.log(volumeVal / 1);
      const audioVolume = this.mapRange(this.dBFS, -96, 0, 0, 8);
      if (audioVolume > this.meeterLevel) {
        this.meeterLevel = audioVolume;
        this.level.emit(this.meeterLevel);
      }
    }, 100);

    this.animationInterval = setInterval(() => {
      this.meeterLevel = this.meeterLevel - 1;
      this.level.emit(this.meeterLevel);
      this.dBFSDisplayValue = this.dBFS.toFixed(0);
    }, 200);
  }

  mapRange(x: number, in_min: number, in_max: number, out_min: number, out_max: number) {
    return Math.ceil(((x - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min);
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });

    if (this.mediaStreamAudioSourceNode) this.mediaStreamAudioSourceNode.disconnect();
    if (this.analyserNode) this.analyserNode.disconnect();
    if (this.audioContext) this.audioContext.close();
    clearInterval(this.grabInterval);
    clearInterval(this.animationInterval);
  }
}
