export const environment = {
  production: true,
  beta: false,
  staging: false,
  firebase: {
    apiKey: 'AIzaSyAGWwXoLGZOvIzt5snCJRoRfyOne30nH4c',
    authDomain: 'squadcast-7fa10.firebaseapp.com',
    projectId: 'squadcast-7fa10',
    databaseURL: 'https://squadcast-7fa10.firebaseio.com',
    storageBucket: 'squadcast-7fa10.appspot.com',
    imageBucket: 'squadcast-7fa10-images',
    recordingBucket: 'squadcast-7fa10-recordings',
    testRecordingBucket: 'squadcast-7fa10-test-recordings',
    messagingSenderId: '540819352137',
    appId: '1:540819352137:web:a851453cd12ddb0d882d98',
    measurementId: 'G-H7GYP0NLR5',
    dynamicLinksURL: 'sqdc.st/studio',
  },
  stripe: {
    publishableKey: 'pk_live_aPZOZWpytkGw7K0quF7y8LzH',
  },
  typesense: {
    nodes: [
      {
        host: '9auotrg7lcm68nzjp.a1.typesense.net',
        port: 443,
        protocol: 'https',
      },
    ],
  },
  auphonic: {
    googleCloudStorage: 'cniXuVUFo5MVMMvxXfarSo',
  },
  recaptcha: {
    siteKey: '6LcitNMUAAAAAPKbw0mpcOUbvFN5TCLNeiIVsoXU',
  },
  slack: {
    xoxbKey: 'xoxb-234361834692-1910750461908-eYEAGjlcYfARssFb8ITkdU8Y',
  },
  descript: {
    webDomain: 'https://web.descript.com',
  },
  dropbox: {
    client_id:
      'sl.A29zvoC0ZLb_lvVh9u-4Kex2p2FmzmoUyLzZHtpqMAyu7Q2cNBOFMqp4LVK4SBhe_LN5aPGuvkhHb54Iwngtwn7nDmtsGnriM64qkWQaoSHspGlSv7SyxxrRScYc3ZrseKdNbBc',
    redirect_uri: 'https://app.squadcast.fm/account/integration',
    response_type: 'code',
  },
  // 🔽  Release Candidates microservices DO NOT DELETE 🔽
  microservices: {
    amplion: 'https://amplion.squadcast.fm',
    bendix: 'https://bendix.squadcast.fm',
    califone: 'https://api.squadcast.fm',
    distatone: 'https://distatone.squadcast.fm',
    farnsworth: 'https://farnsworth.squadcast.fm',
    lafayette: 'https://lafayette.squadcast.fm',
  },
  // 🔽  Release Test Candidates Testing microservices DO NOT DELETE 🔽
  // microservices: {
  //   amplion: 'https://v5-0-rc1-dot-amplion-159518.uc.r.appspot.com',
  //   bendix: 'https://v5-0-rc1-dot-bendix-209020.wl.r.appspot.com',
  //   califone: 'https://v5-0-rc1-dot-califone.wl.r.appspot.com',
  //   distatone: 'https://v5-0-rc1-dot-distatone.uc.r.appspot.com',
  //   farnsworth: 'https://v5-0-rc1-dot-farnsworth-273220.appspot.com',
  //   lafayette: 'https://v5-0-rc1-dot-lafayette-329116.uc.r.appspot.com',
  // },
  // // 🔽  Test Candidates Testing microservices DO NOT DELETE 🔽
  // microservices: {
  //   amplion: 'https://v5-0-tc1-dot-amplion-159518.uc.r.appspot.com',
  //   bendix: 'https://v5-0-tc1-dot-bendix-209020.wl.r.appspot.com',
  //   califone: 'https://v5-0-tc1-dot-califone.wl.r.appspot.com',
  //   distatone: 'https://v5-0-tc1-dot-distatone.uc.r.appspot.com',
  //   farnsworth: 'https://v5-0-tc1-dot-farnsworth-273220.appspot.com',
  //   lafayette: 'https://v5-0-tc1-dot-lafayette-329116.uc.r.appspot.com',
  // },
  rollbar: {
    rollbarToken: 'd000a7f2c6d54aaabf6e660b0ab6c9e8',
  },
  toastr: {
    timeOut: 10000,
    positionClass: 'toast-bottom-right',
    preventDuplicates: true,
  },
};
