<div
  class="media-container"
  [class]="{
    'has-seek-bar': url && !hideSeekBar,
    'active-speaker': isSpeaking && !muted,
    audio: mediaType === 'audio'
  }">
  <span class="is-speaking-border"></span>
  <video
    #mediaStreamPlayer
    [hidden]="!(mediaStream?.getVideoTracks().length > 0) || url || videoOff"
    [class.mirrored]="mirrored"></video>

  <video
    #urlVideoPlayer
    [hidden]="!url || mediaType !== 'video'"
    [autoplay]="!appleDevice && autoplay"
    (mouseenter)="setSeekBarTimeout()"
    (ended)="playEnded()"
    (play)="playStarted()"
    (click)="togglePlay()">
    <source *ngIf="url" [src]="url | trust : 'url'" />
  </video>

  <audio
    #urlAudioPlayer
    [hidden]="!url || mediaType !== 'audio'"
    [autoplay]="!appleDevice && autoplay"
    [src]="url | trust : 'url'"
    (ended)="playEnded()"
    (play)="playStarted()"
    (click)="togglePlay()"></audio>

  <div class="seek-bar-container" #seekBarContainer (mouseenter)="setSeekBarTimeout()" [hidden]="!url || hideSeekBar">
    <ion-button
      [attr.aria-label]="
        mediaType === 'audio' && !playing
          ? 'Play Audio'
          : mediaType === 'video' && !playing
          ? 'Play Video'
          : mediaType === 'audio' && playing
          ? 'Pause Audio'
          : mediaType === 'video' && playing
          ? 'Pause Video'
          : 'Toggle Play'
      "
      (click)="togglePlay()"
      [tooltip]="
        mediaType === 'audio' && !playing
          ? 'Play Audio'
          : mediaType === 'video' && !playing
          ? 'Play Video'
          : mediaType === 'audio' && playing
          ? 'Pause Audio'
          : mediaType === 'video' && playing
          ? 'Pause Video'
          : 'Toggle Play'
      "
      class="line">
      <ion-icon
        [src]="playing ? '/assets/icons/32px/stop-solid-bold.svg' : '/assets/icons/32px/play-solid-bold.svg'"
        slot="icon-only">
      </ion-icon>
    </ion-button>

    <ion-text>
      <span> {{ this.currentDurationWithFormat }} / </span>

      {{ this.totalDurationWithFormat }}
    </ion-text>

    <ion-range
      [(ngModel)]="progress"
      (ngModelChange)="seek()"
      mode="ios"
      pin="false"
      [class.disabled]="seekBarDisabled">
    </ion-range>
  </div>

  <div
    class="play-button"
    (click)="togglePlay(true)"
    *ngIf="
      !playing &&
      mediaType === 'video' &&
      url &&
      (currentDurationWithFormat === '00:00:00' || currentDurationWithFormat === totalDurationWithFormat || hideSeekBar)
    ">
    <ion-icon src="/assets/icons/32px/play-solid-bold.svg" color="dark"> </ion-icon>
  </div>

  <div *ngIf="(!(mediaStream?.getVideoTracks().length > 0) && !url) || videoOff" id="avatar">
    <sc-avatar
      *ngIf="videoOff || !isLocalParticipant"
      [photoURL]="placeholderPhotoURL"
      [name]="placeholderName"></sc-avatar>
    <ion-spinner *ngIf="!videoOff && isLocalParticipant"></ion-spinner>
  </div>

  <div id="av-quality" *ngIf="(conversation$ | async) === null">
    <ion-badge *ngIf="audioSettings?.sampleRate && !muted" color="medium" tooltip="Audio Sample Rate">
      {{ audioSettings?.sampleRate.toString().substring(0, 2) }}
      kHz
    </ion-badge>

    <ion-badge *ngIf="mediaStream?.getVideoTracks()?.[0] && !videoOff" color="medium" tooltip="Video Resolution">
      {{ videoResolution }}
    </ion-badge>

    <ion-badge *ngIf="mediaStream?.getVideoTracks()?.[0] && !videoOff" color="medium" tooltip="Video Frame Rate">
      {{ videoSettings?.frameRate?.toFixed(0) }} fps
    </ion-badge>
  </div>

  <div id="controls" *ngIf="showControls">
    <ion-button
      size="large"
      [tooltip]="(mute$ | async) ? 'Turn Microphone On' : 'Turn Microphone Off'"
      (click)="toggleMicrophone()"
      [class.off]="mute$ | async">
      <span class="sr-only">{{ (mute$ | async) ? 'Turn Microphone On' : 'Turn Microphone Off' }}</span>
      <ion-icon
        slot="icon-only"
        [src]="
          (mute$ | async) ? '/assets/icons/24px/microphone-off.svg' : '/assets/icons/24px/microphone.svg'
        "></ion-icon>
    </ion-button>
    <ion-button
      size="large"
      *ngIf="!noCamera"
      [tooltip]="(videoOff$ | async) ? 'Turn Video On' : 'Turn Video Off'"
      (click)="toggleVideo()"
      [class.off]="videoOff$ | async">
      <span class="sr-only">{{ (videoOff$ | async) ? 'Turn Video On' : 'Turn Video Off' }}</span>
      <ion-icon
        slot="icon-only"
        [src]="(videoOff$ | async) ? '/assets/icons/24px/camera-off.svg' : '/assets/icons/24px/camera.svg'"></ion-icon>
    </ion-button>
  </div>

  <sc-vu-meter
    *ngIf="!muted && !url && !isScreenShare && (audioStream || mediaStream) && (!isDolbyVoice || isLocalParticipant)"
    (level)="onAudioLevel($event)"
    [stream]="audioStream || mediaStream"></sc-vu-meter>
  <ion-icon
    *ngIf="muted && !url && !isScreenShare && !showControls"
    src="/assets/icons/24px/microphone-off.svg"
    tooltip="Microphone Muted"
    color="danger"
    id="mute">
  </ion-icon>
</div>
