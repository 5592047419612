import { Injectable } from '@angular/core';
import { Firestore, collection, doc, docData, setDoc } from '@angular/fire/firestore';

import { Participant, SessionsCollection } from '@sc/types';
import { SessionsService } from '../sessions/sessions.service';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ColorsService {
  studioSession$ = this.sessionsService.studioSession$;
  sessionsCol = collection(this.firestore, 'sessions');
  chatsCol = collection(this.firestore, 'chats');

  accentColors: Array<string> = [
    'sc-color-accent-orange-100-bg',
    'sc-color-accent-blue-100-bg',
    'sc-color-accent-1-bg',
    'sc-color-accent-2-bg',
    'sc-color-accent-3-bg',
    'sc-color-accent-4-bg',
    'sc-color-accent-5-bg',
    'sc-color-accent-6-bg',
    'sc-color-accent-7-bg',
    'sc-color-accent-8-bg',
    'sc-color-accent-9-bg',
    'sc-color-accent-11-bg',
    'sc-color-accent-12-bg',
  ];

  constructor(private firestore: Firestore, private sessionsService: SessionsService) {}

  getRandomAccentColor() {
    return this.accentColors[Math.floor(Math.random() * this.accentColors.length)];
  }

  /**
   * Sets the Participants color Field in Firestore.
   *
   * @param color - string
   * @param participant - Participant
   * @param sessionID - string
   * @returns Promise<void>
   */
  setParticipantColor(participantID: string, color: string, sessionID?: string) {
    if (!sessionID) sessionID = this.studioSession$.value.sessionID;

    return setDoc(
      doc(this.sessionsCol, sessionID, SessionsCollection.PARTICIPANTS, participantID),
      { color },
      { merge: true }
    );
  }

  /**
   * Gets the Participants color Field from Firestore.
   *
   * @param participantID - string
   * @param sessionID - string
   * @returns Promise<void>
   */
  getParticipantColor(participantID: string, sessionID?: string) {
    if (!sessionID) sessionID = this.studioSession$.value.sessionID;

    return docData<Participant>(doc(this.sessionsCol, sessionID, SessionsCollection.PARTICIPANTS, participantID)).pipe(
      map((participant) => participant?.color)
    );
  }

  /**
   * Sets the Chat Groups color Field in Firestore.
   *
   * @param color - string
   * @param chatGroupID - string
   * @param sessionID - string
   * @returns Promise<void>
   */
  setChatGroupColor(chatGroupID: string, color: string, sessionID?: string) {
    if (!sessionID) sessionID = this.studioSession$.value.sessionID;

    return setDoc(doc(this.chatsCol, sessionID, 'groups', chatGroupID), { color }, { merge: true });
  }
}
