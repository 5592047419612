import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { StripeModule } from 'stripe-angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MentionModule } from 'angular-mentions';
import { NgAisModule } from 'angular-instantsearch';

import { SquadCastDirectivesModule } from '../directives/squadcast.directives.module';
import { SquadCastPipesModule } from '../pipes/squadcast.pipes.module';
import { A11yModule } from '@angular/cdk/a11y';

import { AccountSidebarComponent } from './account-sidebar/account-sidebar.component';
import { AddSidebarComponent } from './add-sidebar/add-sidebar.component';
import { AisDateComponent } from './ais-date/ais-date.component';
import { AisMenuComponent } from './ais-menu/ais-menu.component';
import { AisSortComponent } from './ais-sort/ais-sort.component';
import { AuphonicSettingsComponent } from './auphonic-settings/auphonic-settings.component';
import { AvatarComponent } from './avatar/avatar.component';
import { BackstageAccessComponent } from './backstage-access/backstage-access.component';
import { BannerComponent } from './banner/banner.component';
import { CanvasComponent } from './canvas/canvas.component';
import { ChatComponent } from './chat/chat.component';
import { ChatGroupComponent } from './chat-group/chat-group.component';
import { ChatGroupsComponent } from './chat-groups/chat-groups.component';
import { ChatMessageComponent } from './chat-message/chat-message.component';
import { CloudRecordingListItemComponent } from './cloud-recording-list-item/cloud-recording-list-item.component';
import { CountdownComponent } from './countdown/countdown.component';
import { CreateSessionPopoverComponent } from './create-session-popover/create-session-popover.component';
import { DashboardHeaderComponent } from './dashboard-header/dashboard-header.component';
import { DashboardLayoutComponent } from './dashboard-layout/dashboard-layout.component';
import { DolbySettingsComponent } from './dolby-settings/dolby-settings.component';
import { EquipmentListComponent } from './equipment-list/equipment-list.component';
import { GifSelectorComponent } from './gif-selector/gif-selector.component';
import { GreenRoomComponent } from './green-room/green-room.component';
import { HelpButtonComponent } from './help-button/help-button.component';
import { InputDateComponent } from './input-date/input-date.component';
import { InviteMembersComponent } from './invite-members/invite-members.component';
import { LearnMoreComponent } from './learn-more/learn-more.component';
import { MiniNotificationComponent } from './mini-notification/mini-notification.component';
import { NameMenuComponent } from './name-menu/name-menu.component';
import { ParticipantComponent } from './participant/participant.component';
import { ProfileHeaderComponent } from './profile-header/profile-header.component';
import { ParticipantEquipmentComponent } from './participant-equipment/participant-equipment.component';
import { ParticipantsComponent } from './participants/participants.component';
import { PaymentInfoComponent } from './payment-info/payment-info.component';
import { PlanComponent } from './plan/plan.component';
import { PresetListItemComponent } from './preset-list-item/preset-list-item.component';
import { PreviewTestComponent } from './preview-test/preview-test.component';
import { RecentTakesComponent } from './recent-takes/recent-takes.component';
import { RecentTakeComponent } from './recent-take/recent-take.component';
import { RecordingListItemComponent } from './recording-list-item/recording-list-item.component';
import { RecordingsComponent } from './recordings/recordings.component';
import { RecordingsTagComponent } from './recordings-tag/recordings-tag.component';
import { RecordingsTakeComponent } from './recordings-take/recordings-take.component';
import { RecordingsTakesComponent } from './recordings-takes/recordings-takes.component';
import { RequestPermissionComponent } from './request-permission/request-permission.component';
import { RoleTagComponent } from './role-tag/role-tag.component';
import { SalesCardComponent } from './sales-card/sales-card.component';
import { ShowsListComponent } from './shows-list/shows-list.component';
import { SquadshotComponent } from './squadshot/squadshot.component';
import { SquadshotListItemComponent } from './squadshot-list-item/squadshot-list-item.component';
import { SessionComponent } from './session/session.component';
import { SessionCloudRecordingsComponent } from './session-cloud-recordings/session-cloud-recordings.component';
import { SessionSquadshotsComponent } from './session-squadshots/session-squadshots.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { ShowsNavComponent } from './shows-nav/shows-nav.component';
import { ShowListItemComponent } from './show-list-item/show-list-item.component';
import { ShowSessionLabelComponent } from './show-session-label/show-session-label.component';
import { StatsForNerdsComponent } from './stats-for-nerds/stats-for-nerds.component';
import { StudioControlsComponent } from './studio-controls/studio-controls.component';
import { StudioNavComponent } from './studio-nav/studio-nav.component';
import { StudioSidebarComponent } from './studio-sidebar/studio-sidebar.component';
import { TeamListComponent } from './team-list/team-list.component';
import { TeamMemberListItemComponent } from './team-member-list-item/team-member-list-item.component';
import { TimeWalletComponent } from './time-wallet/time-wallet.component';
import { ThemeButtonComponent } from './theme-button/theme-button.component';
import { UploadStatsComponent } from '../components/upload-stats/upload-stats.component';
import { PlayerComponent } from './player/player.component';
import { VuMeterComponent } from './vu-meter/vu-meter.component';
import { WorkflowDrawerComponent } from './workflow-drawer/workflow-drawer.component';

import { GeneralToastComponent } from '../toasts/general-toast/general-toast.component';
import { LeaveFeedbackToastComponent } from '../toasts/leave-feedback-toast/leave-feedback-toast.component';
import { StickAroundToastComponent } from '../toasts/stick-around-toast/stick-around-toast.component';
import { EditInDescriptToastComponent } from '../toasts/edit-in-descript-toast/edit-in-descript-toast.component';
import { StudioControlsButtonComponent } from './studio-controls-button/studio-controls-button.component';
import { TestEquipmentComponent } from './test-equipment/test-equipment.component';
import { DriveSelectComponent } from './drive-select/drive-select.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    IonicModule,
    StripeModule,
    NgSelectModule,
    MatProgressSpinnerModule,
    MentionModule,
    NgAisModule.forRoot(),

    SquadCastDirectivesModule,
    SquadCastPipesModule,
    A11yModule,
  ],
  declarations: [
    AccountSidebarComponent,
    AddSidebarComponent,
    AisDateComponent,
    AisMenuComponent,
    AisSortComponent,
    AuphonicSettingsComponent,
    AvatarComponent,
    BackstageAccessComponent,
    BannerComponent,
    CanvasComponent,
    ChatComponent,
    ChatGroupComponent,
    ChatGroupsComponent,
    ChatMessageComponent,
    CloudRecordingListItemComponent,
    CountdownComponent,
    CreateSessionPopoverComponent,
    DashboardHeaderComponent,
    DashboardLayoutComponent,
    DolbySettingsComponent,
    EquipmentListComponent,
    GeneralToastComponent,
    GifSelectorComponent,
    GreenRoomComponent,
    HelpButtonComponent,
    InputDateComponent,
    InviteMembersComponent,
    LearnMoreComponent,
    LeaveFeedbackToastComponent,
    MiniNotificationComponent,
    NameMenuComponent,
    ParticipantComponent,
    ParticipantEquipmentComponent,
    ParticipantsComponent,
    PaymentInfoComponent,
    PlanComponent,
    PresetListItemComponent,
    PreviewTestComponent,
    ProfileHeaderComponent,
    RecentTakesComponent,
    RecentTakeComponent,
    RecordingListItemComponent,
    RecordingsComponent,
    RecordingsTagComponent,
    RecordingsTakeComponent,
    RecordingsTakesComponent,
    RequestPermissionComponent,
    RoleTagComponent,
    SalesCardComponent,
    ShowsListComponent,
    SessionComponent,
    SessionCloudRecordingsComponent,
    SessionSquadshotsComponent,
    SearchBarComponent,
    ShowsNavComponent,
    ShowListItemComponent,
    ShowSessionLabelComponent,
    SquadshotComponent,
    SquadshotListItemComponent,
    StatsForNerdsComponent,
    StickAroundToastComponent,
    EditInDescriptToastComponent,
    StudioControlsComponent,
    StudioControlsButtonComponent,
    StudioNavComponent,
    StudioSidebarComponent,
    TeamListComponent,
    TeamMemberListItemComponent,
    TimeWalletComponent,
    ThemeButtonComponent,
    UploadStatsComponent,
    PlayerComponent,
    VuMeterComponent,
    WorkflowDrawerComponent,
    AddSidebarComponent,
    TestEquipmentComponent,
    DriveSelectComponent,
  ],
  exports: [
    A11yModule,
    AccountSidebarComponent,
    AddSidebarComponent,
    AisDateComponent,
    AisMenuComponent,
    AisSortComponent,
    AuphonicSettingsComponent,
    AvatarComponent,
    BackstageAccessComponent,
    BannerComponent,
    CanvasComponent,
    ChatComponent,
    ChatGroupComponent,
    ChatGroupsComponent,
    ChatMessageComponent,
    CloudRecordingListItemComponent,
    CountdownComponent,
    CreateSessionPopoverComponent,
    DashboardHeaderComponent,
    DashboardLayoutComponent,
    DolbySettingsComponent,
    EquipmentListComponent,
    GeneralToastComponent,
    GifSelectorComponent,
    GreenRoomComponent,
    HelpButtonComponent,
    InputDateComponent,
    InviteMembersComponent,
    LearnMoreComponent,
    LeaveFeedbackToastComponent,
    MiniNotificationComponent,
    NameMenuComponent,
    ParticipantComponent,
    ParticipantEquipmentComponent,
    ParticipantsComponent,
    PaymentInfoComponent,
    PlanComponent,
    PresetListItemComponent,
    PreviewTestComponent,
    ProfileHeaderComponent,
    RecentTakesComponent,
    RecentTakeComponent,
    RecordingListItemComponent,
    RecordingsComponent,
    RecordingsTagComponent,
    RecordingsTakeComponent,
    RecordingsTakesComponent,
    RequestPermissionComponent,
    RoleTagComponent,
    SalesCardComponent,
    ShowsListComponent,
    SessionComponent,
    SessionCloudRecordingsComponent,
    SessionSquadshotsComponent,
    SearchBarComponent,
    ShowsNavComponent,
    ShowListItemComponent,
    ShowSessionLabelComponent,
    SquadshotComponent,
    SquadshotListItemComponent,
    StatsForNerdsComponent,
    StickAroundToastComponent,
    EditInDescriptToastComponent,
    StudioControlsComponent,
    StudioControlsButtonComponent,
    StudioNavComponent,
    StudioSidebarComponent,
    TeamListComponent,
    TeamMemberListItemComponent,
    TimeWalletComponent,
    ThemeButtonComponent,
    UploadStatsComponent,
    PlayerComponent,
    VuMeterComponent,
    WorkflowDrawerComponent,
    DriveSelectComponent,

    SquadCastDirectivesModule,
    SquadCastPipesModule,

    NgSelectModule,
  ],
})
export class SquadCastComponentsModule {}
