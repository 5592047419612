import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { firstValueFrom, Observable, of, Subscription, switchMap } from 'rxjs';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ActivatedRoute, Router } from '@angular/router';

import { AnalyticsService } from '../../services/analytics/analytics.service';
import { LafayetteService } from '../../services/lafayette/lafayette.service';
import { UserService } from '../../services/user/user.service';
import { OrganizationsService } from '../../services/organizations/organizations.service';
import { ShowsService } from '../../services/shows/shows.service';
import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';
import { CropPhotoPage } from '../../modals/crop-photo/crop-photo.page';
import {
  ConfTypes,
  CreateShowDto,
  RecordingTones,
  ShowMember,
  ShowSettings,
  TeamMemberInvite,
  UpdateShowDto,
} from '@sc/types';
import { TeamMember } from '@sc/types';
import { Roles } from '@sc/types';
import { ServerResponse } from '@sc/types';
import { WalletService } from '../../services/wallet/wallet.service';
import { TeamsService } from '../../services/teams/teams.service';
import { AdvancedShowSettingsPage } from '../../modals/advanced-show-settings/advanced-show-settings.page';
import { SCSubject } from '../../util/sc-subject.class';
import { LinksService } from '../../services/links/links.service';
import { ZapierService } from '../../services/zapier/zapier.service';
import { RoleLimits, RoleDisplayNames } from '@sc/types';
import { RolesService } from '../../services/roles/roles.service';
import { WebhookEvent, WebhookEventNames } from '@sc/types';
import { CalifoneService } from '../../services/califone/califone.service';
import { RolesInfoMenuComponent } from '../../menus/roles-info-menu/roles-info-menu.component';
import { TeamInvite } from '@sc/types';
import { defaultPopoverOptions } from '../../services/theme/theme.service';
import { stripHTML } from '../../util/stripHTML';
import { ActionConfirmPage } from '../../modals/action-confirm/action-confirm.page';
import { SettingsService } from '../../services/settings/settings.service';

@Component({
  selector: 'sc-add-show',
  templateUrl: './add-show.page.html',
  styleUrls: ['./add-show.page.scss'],
})
export class AddShowPage implements OnInit, OnDestroy {
  @ViewChild('showMembersSelect') ngSelect: NgSelectComponent;
  @ViewChild('.roleSelect') roleSelect: NgSelectComponent;

  plan$ = this.walletService.dashboardPlan$;
  shows$ = this.showsService.availableShows$;
  availableShowCountTooltip$: Observable<string> = new SCSubject();
  activeUser$ = this.userService.activeUser$;
  role$ = this.rolesService.role$;
  dashboardShow$ = this.showsService.dashboardShow$;

  roleLimits = RoleLimits;
  roles = Roles;
  newShow: CreateShowDto;

  editing = false;
  saving = false;
  editingShow: UpdateShowDto;
  imageSelected = false;
  showID: string;

  showName: string;
  showDescription: string;
  availableMembers: Array<TeamMember> = [];
  showMembers: Array<TeamMember & { externalInvite?: TeamInvite; invite?: boolean }> = [];
  imageFile: string;
  imageSelection = false;
  subs: Array<Subscription> = [];

  settings: ShowSettings;
  ConfTypes = ConfTypes;
  RecordingTones = RecordingTones;
  selectedRecordingTone = RecordingTones.NONE;

  constructor(
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private lafayetteService: LafayetteService,
    private modalController: ModalController,
    private organizationsService: OrganizationsService,
    private router: Router,
    private showsService: ShowsService,
    private teamsService: TeamsService,
    private toastrService: ToastrService,
    private userService: UserService,
    private walletService: WalletService,
    private linksService: LinksService,
    private zapierService: ZapierService,
    private rolesService: RolesService,
    private califoneService: CalifoneService,
    private popoverController: PopoverController,
    private settingsService: SettingsService
  ) {}

  ngOnInit() {
    this.setupEditShow();
    this.setupAvailableMembers();
    this.setupShowMembers();
    this.setupAvailableShowCountTooltip();
    this.setupSettings();
  }

  reset() {
    this.imageSelected = false;
    this.showID = null;

    this.showName = '';
    this.showDescription = '';
    this.showMembers = [];
    this.imageFile = '';
    this.imageSelection = false;
    this.saving = false;
  }

  setupAvailableMembers() {
    const sub = this.teamsService.dashboardOrgMembers$.subscribe(async (members: TeamMember[]) => {
      if (this.saving) return;

      members.map((member: TeamMember) => {
        if (member.role > Roles.SHOW_MANAGER) member.role = Roles.SHOW_MANAGER;
      });
      this.availableMembers = members;
    });
    this.subs.push(sub);
  }

  setupShowMembers() {
    const sub = this.role$.subscribe(async (role) => {
      if (role < this.roleLimits.SHOW_UPDATE) return;

      if (this.editing) {
        await this.teamsService.dashboardOrgMembers$.toPromise();
        const showMembers = await firstValueFrom(this.showsService.getShowMembers(this.showID));

        showMembers.forEach((showMem) => {
          const avail = this.ngSelect.itemsList.items.find((i) => i.value.uid === showMem.uid);
          avail.value.role = Math.min(showMem.role, Roles.SHOW_MANAGER);
          this.ngSelect.itemsList.select(avail);
        });
        this.showMembers = this.ngSelect.itemsList.selectedItems.map((i) => i.value);
        this.updateShowMembersWithInvites();
      } else {
        await new Promise((resolve) => setTimeout(resolve, 0)); // let ngSelect update

        const self = this.ngSelect.itemsList.items.find((i) => i.value.uid === this.activeUser$.value.uid);
        this.ngSelect.itemsList.select(self);
        this.memberAdd(self.value);
      }
    });

    this.subs.push(sub);
  }

  setupEditShow() {
    const sub = this.activatedRoute.params.subscribe(async (params) => {
      this.reset();
      if (params.id) {
        this.editing = true;
        this.showID = params.id;
        this.showsService.setDashboardShow(this.showID);
        const show = await this.showsService.dashboardShow$.nextExistingValue();
        this.showDescription = show.showSubtitle ?? '';
        this.showName = show.showName;
        this.imageFile = show.showImg;
        this.imageSelection = true;
        this.editingShow = { ...show, showMembers: [] };
      }
    });

    this.subs.push(sub);
  }

  setupAvailableShowCountTooltip() {
    this.availableShowCountTooltip$ = this.shows$.pipe(
      switchMap((shows) => {
        const availableShowCountTooltip =
          typeof this.plan$.value.shows === 'string'
            ? 'Unlimited Shows Available'
            : this.plan$.value.shows - shows.length <= 0
            ? `Upgrade to Create New Shows`
            : `${this.plan$.value.shows - shows.length} Available`;

        return of(availableShowCountTooltip);
      })
    );
  }

  async updateShowMembersWithInvites() {
    const invites = await firstValueFrom(this.teamsService.getInvitesByShow(this.showID));
    invites.forEach((invite) => {
      const member = this.showMembers.find((m) => m.email === invite.email);
      if (!member) {
        this.showMembers.push({
          email: invite.email,
          role: invite.role,
          externalInvite: invite,
        });
      }
    });
  }

  async cancelInvite(invite: TeamInvite) {
    await this.teamsService.cancelInvite(invite.inviteID);
    this.showMembers = this.showMembers.filter((m) => m.email !== invite.email);
    this.toastrService.success(invite.email, 'Invite revoked', {
      progressBar: true,
      progressAnimation: 'decreasing',
      closeButton: true,
      tapToDismiss: false,
      timeOut: 5 * 1000,
      toastComponent: GeneralToastComponent,
    });
    await this.analyticsService.track('opened revoke team member invitation', {
      orgID: invite.orgID,
      orgName: invite.orgName,
      email: invite.email,
      invitedBy: invite.invitedBy,
      inviteTimestamp: invite.inviteTimestamp,
    });
  }

  async memberAdd(member: TeamMember) {
    const existing = this.showMembers.find(
      (m) => (m.uid && m.uid === member.uid) || (m.email && m.email === member.email)
    );
    if (existing) return;
    member.role = Math.min(member.role, Roles.SHOW_MANAGER);
    this.showMembers.push(member);
    await this.analyticsService.track('added team member');
  }

  async roleChange(e: ICustomEvent, member: TeamMember) {
    const newRole = e.detail.value;
    if (newRole === 'remove') {
      this.removeMember(member);
      return;
    }
    const showMember = this.showMembers.find(
      (m) => (m.uid && m.uid === member.uid) || (m.email && m.email === member.email)
    );
    if (showMember.role !== newRole) showMember.role = +newRole;
    await this.analyticsService.track('changed team member role', {
      email: member.email,
      role: member.role,
      uid: member.uid,
    });
  }

  async removeMember(member: TeamMember) {
    const item = this.ngSelect.itemsList.findItem(member);
    if (item) this.ngSelect.itemsList.unselect(item);
    this.showMembers = this.ngSelect.itemsList.selectedItems.map((i) => i.value);
    this.updateShowMembersWithInvites();
    await this.analyticsService.track('removed team member', {
      email: member.email,
      role: member.role,
      uid: member.uid,
    });
  }

  addTag(email: string) {
    const isEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (isEmail.test(email)) return { email, role: Roles.FILE_MANAGER, invite: true };
  }

  /**
   * Creates a Show by calling the Lafayette Service
   */
  async createShow() {
    if (this.plan$.value.shows !== 'Unlimited' && this.shows$.value?.length >= this.plan$.value?.shows) {
      this.router.navigate(['account/subscription']);
    } else {
      this.saving = true;
      const orgMembers = this.showMembers.filter((m) => m.uid) as ShowMember[];
      this.showName = stripHTML(this.showName);
      this.showDescription = stripHTML(this.showDescription);
      const name = this.showName;

      this.newShow = {
        showName: this.showName,
        showSubtitle: this.showDescription,
        showOwner: this.activeUser$.value.uid,
        orgID: this.organizationsService.dashboardOrgID$.value,
        showMembers: orgMembers,
      };

      this.lafayetteService
        .createShow(this.newShow)
        .then(async (resp: ServerResponse) => {
          if (this.imageFile) {
            await this.showsService.uploadShowImage(resp.ID, this.imageFile);
          }
          this.showsService.setDashboardShow(resp.ID);
          return resp.ID;
        })
        .then(async (showID: string) => {
          const inviteMembers = this.showMembers.filter((m) => m.invite);
          const promises = [];
          inviteMembers.forEach((m) => {
            if (this.teamsService.emailIsOrgMember(m.email)) {
              const toast: ActiveToast<GeneralToastComponent> = this.toastrService.info(
                `The email ${m.email} already belongs to a user inside your organization. Please go to Members page to manage organization users.`,
                'Invite Information',
                {
                  closeButton: true,
                  tapToDismiss: false,
                  disableTimeOut: true,
                  toastComponent: GeneralToastComponent,
                }
              );
              toast.toastRef.componentInstance.buttons = [
                {
                  label: 'Take Me There',
                  handler: () => {
                    this.router.navigate(['account/members']);
                  },
                },
              ];

              return;
            }

            promises.push(this.teamsService.createInvite(m.email, m.role, showID));
            promises.push(this.sendTeamMemberInviteEmail(m));
          });
          await Promise.all(promises);
          this.reset();
          this.toastrService.success('Successfully created your new show!', `${name} created!`, {
            progressBar: true,
            progressAnimation: 'decreasing',
            closeButton: true,
            tapToDismiss: false,
            timeOut: 10 * 1000,
            toastComponent: GeneralToastComponent,
          });

          await this.router.navigate(['/dashboard'], { replaceUrl: true });
          await this.analyticsService.track('opened create show', {
            showName: this.newShow.showName,
            showOwner: this.newShow.showOwner,
            orgID: this.newShow.orgID,
            success: true,
          });

          const event: WebhookEvent = {
            name: WebhookEventNames.SHOW_CREATED,
            userName: this.activeUser$.value.displayName || this.activeUser$.value.email || 'Someone',
            orgID: this.organizationsService.dashboardOrgID$.value,
            showName: this.newShow.showName,
            showSubtitle: this.newShow.showSubtitle,
            showOwner: this.newShow.showOwner,
            showID,
          };
          await this.califoneService.emitWebhookEvent(event, this.organizationsService.dashboardOrgID$.value);
        })
        .catch(async (err) => {
          this.saving = false;
          this.toastrService.error('Oops! Unable to create show', `Show Creation Failure!`, {
            progressBar: true,
            progressAnimation: 'decreasing',
            closeButton: true,
            tapToDismiss: false,
            timeOut: 10 * 1000,
            toastComponent: GeneralToastComponent,
          });
          await this.analyticsService.track('opened create show', {
            showName: this.newShow.showName,
            showOwner: this.newShow.showOwner,
            orgID: this.newShow.orgID,
            success: false,
          });
        });
    }
  }

  /**
   * Edits the Show by calling Lafayette Service
   */
  async editShow() {
    this.saving = true;
    const members = this.showMembers.filter((m) => m.uid) as ShowMember[];
    this.showName = stripHTML(this.showName);
    this.showDescription = stripHTML(this.showDescription);
    const name = this.showName;

    this.editingShow.showName = this.showName;
    this.editingShow.showSubtitle = this.showDescription;
    this.editingShow.showMembers = members;

    await this.lafayetteService
      .updateShow(this.editingShow)
      .then(async (resp: ServerResponse) => {
        if (this.imageFile && this.imageSelected) {
          await this.showsService.uploadShowImage(this.editingShow.showID, this.imageFile);
        }
      })
      .then(async () => {
        const inviteMembers = this.showMembers.filter((m) => m.invite);
        const promises = [];
        inviteMembers.forEach((m) => {
          if (this.teamsService.emailIsOrgMember(m.email)) {
            const toast: ActiveToast<GeneralToastComponent> = this.toastrService.info(
              `The email ${m.email} already belongs to a user inside your organization. Please go to Members page to manage organization users.`,
              'Invite Information',
              {
                closeButton: true,
                tapToDismiss: false,
                disableTimeOut: true,
                toastComponent: GeneralToastComponent,
              }
            );
            toast.toastRef.componentInstance.buttons = [
              {
                label: 'Take Me There',
                handler: () => {
                  this.router.navigate(['account/members']);
                },
              },
            ];

            return;
          }

          promises.push(this.teamsService.createInvite(m.email, m.role, this.editingShow.showID));
          promises.push(this.sendTeamMemberInviteEmail(m));
        });
        await Promise.all(promises);
        this.reset();
        this.toastrService.success('Successfully edited your show!', `${name} edited!`, {
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
          tapToDismiss: false,
          timeOut: 10 * 1000,
          toastComponent: GeneralToastComponent,
        });

        this.analyticsService.track('edited show', {
          showName: this.showName,
          showID: this.showID,
          success: true,
        });
        this.router.navigate(['/dashboard'], { replaceUrl: true });

        const event: WebhookEvent = {
          name: WebhookEventNames.SHOW_UPDATED,
          userName: this.activeUser$.value.displayName,
          orgID: this.organizationsService.dashboardOrgID$.value,
          showName: this.editingShow.showName,
          showSubtitle: this.editingShow.showSubtitle,
          showOwner: this.editingShow.showOwner,
          showID: this.editingShow.showID,
        };
        await this.califoneService.emitWebhookEvent(event, this.organizationsService.dashboardOrgID$.value);
      })
      .catch((err) => {
        this.saving = false;
        console.error(err);
        this.toastrService.error('Oops! Unable to edit show', `Show Edit Failure!`, {
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
          tapToDismiss: false,
          timeOut: 10 * 1000,
          toastComponent: GeneralToastComponent,
        });
        this.analyticsService.track('edited show', {
          showName: this.showName,
          showID: this.showID,
          success: false,
        });
      });
  }

  /**
   * Event triggered when user selects an image file
   */
  async photoInputChange(event: Event) {
    if (!(event.target instanceof HTMLInputElement) || event.target.files.length === 0) return;
    await this.openCropPhotoModal(event);
    event.target.value = ''; // If the user closes the modal and selects the same file, this will fix modal not re-appearing.
  }

  /**
   * Creates the modal for cropping the image
   */
  async openCropPhotoModal(event: any) {
    const cropOptions = {
      roundCropper: false,
    };
    const modal = await this.modalController.create({
      component: CropPhotoPage,
      componentProps: { user: this.activeUser$.value, event, options: cropOptions },
      showBackdrop: true,
      backdropDismiss: true,
      animated: true,
      cssClass: 'crop-photo-modal',
    });

    modal.onDidDismiss().then(({ data }) => {
      if (data?.base64) {
        this.imageSelected = true;
        this.imageFile = data.base64;
        this.imageSelection = true;
      }
    });

    await modal.present();
  }

  openAdvancedSettings() {
    this.modalController
      .create({
        component: AdvancedShowSettingsPage,
        showBackdrop: true,
        backdropDismiss: true,
        animated: true,
        cssClass: 'advanced-show-settings-modal',
      })
      .then((modal) => {
        modal.present();
      });
  }

  cancel() {
    this.router.navigate(['/dashboard'], { replaceUrl: true });
  }

  async sendTeamMemberInviteEmail(member: TeamMember) {
    const { protocol, host } = window.location;
    const title = `${this.showName} has invited you to join their Show`;
    const inviteDoc = await this.teamsService.createInvite(member.email, member.role, this.showID);
    const inviteID = inviteDoc.id;
    const shortLink: any = await this.linksService.getShortLink({
      url: `${protocol}//${host}/auth/${member.email}`,
      title,
      description: `🔴 Invited to SquadCast`,
    });
    const parsed = JSON.parse(shortLink);
    const teamMemberShowInvite: TeamMemberInvite = {
      inviteID,
      fromEmail: this.activeUser$.value.email,
      toEmail: member.email.toLowerCase(),
      role: member.role,
      orgName: this.organizationsService.dashboardOrg$.value.orgName,
      title,
      inviteURL: parsed.shortLink,
      showName: this.showName,
      showImg: this.showsService.dashboardShow$?.value.showImg || this.imageFile,
    };
    await this.teamsService.updateInvite(teamMemberShowInvite);
    await this.zapierService.sendTeamMemberShowInviteEmail(inviteID);
    await this.analyticsService.track('sent team member invite for show', {
      showID: this.showID,
      inviteEmail: member.email.toLowerCase(),
    });
  }

  async openRolesInfoMenu(event) {
    const menu = await this.popoverController.create({
      ...defaultPopoverOptions,
      component: RolesInfoMenuComponent,
      event,
    });
    await menu.present();
  }

  setupSettings() {
    const sub = this.settingsService.dashboardShowSettings$.subscribe((settings) => {
      if (Number.isNaN(settings.recordingCountdown)) settings.recordingCountdown = 0;
      this.settings = settings;
      if (settings.recordingTone) this.selectedRecordingTone = settings.recordingTone;
    });

    this.subs.push(sub);
  }

  setConfType(type: ConfTypes) {
    if (this.settings.defaultConfType === type) return;
    const newSettings: ShowSettings = { defaultConfType: type };
    if (this.settings.dolbyClassic || this.settings.dolbyClassic === false) newSettings.dolbyClassic = null;
    if (this.settings.voiceConference || this.settings.voiceConference === false) newSettings.voiceConference = null;

    this.settingsService.setShowSettings(newSettings, this.dashboardShow$.value.showID);
    this.analyticsService.track(`set show default conference type`, {
      showID: this.dashboardShow$.value.showID,
      confType: type,
    });
  }

  toggleFullHD(event: ICustomEvent) {
    this.settingsService.setShowSettings({ fullHD: event.detail.checked }, this.dashboardShow$.value.showID);
    this.analyticsService.track(`toggled full hd`, {
      showID: this.dashboardShow$.value.showID,
      enabled: event.detail.checked,
    });
  }

  toggleDefaultSessionQuality(event: ICustomEvent) {
    this.settingsService.setShowSettings({ maxQuality: event.detail.checked }, this.dashboardShow$.value.showID);
    this.analyticsService.track(`toggled default session quality`, {
      showID: this.dashboardShow$.value.showID,
      enabled: event.detail.checked,
    });
  }

  togglePCM(event: ICustomEvent) {
    this.settingsService.setShowSettings({ rawPCM: event.detail.checked }, this.dashboardShow$.value.showID);
    this.analyticsService.track(`toggled raw pcm`, {
      showID: this.dashboardShow$.value.showID,
      enabled: event.detail.checked,
    });
  }

  toggleDolbyClassic(event: ICustomEvent) {
    this.settingsService.setShowSettings({ dolbyClassic: event.detail.checked }, this.dashboardShow$.value.showID);
    this.analyticsService.track(`toggled dolby classic conference`, {
      showID: this.dashboardShow$.value.showID,
      enabled: event.detail.checked,
    });
  }

  changeRecordingCountdown(event: ICustomEvent) {
    this.settingsService.setShowSettings({ recordingCountdown: event.detail.value }, this.dashboardShow$.value.showID);
    this.analyticsService.track(`toggled countdown`, {
      showID: this.dashboardShow$.value.showID,
      value: event.detail.value,
    });
  }

  changeRecordingTone(value: RecordingTones) {
    this.settingsService.setShowSettings({ recordingTone: value }, this.dashboardShow$.value.showID);
    this.analyticsService.track(`changed recording tone`, {
      showID: this.dashboardShow$.value.showID,
      value,
    });
  }

  async playRecordingTone() {
    if (this.selectedRecordingTone === RecordingTones.SCREEN_READER) {
      const alert = document.getElementById('aria-alert');
      alert.setAttribute('aria-label', 'Recording Started');
      await new Promise((resolve) => setTimeout(resolve, 1200));
      alert.setAttribute('aria-label', 'Recording Stopped');
    } else {
      const start = new Audio(`assets/audio/record-start${this.selectedRecordingTone}.mp3`);
      start.onended = async () => {
        await new Promise((resolve) => setTimeout(resolve, 200));
        const stop = new Audio(`assets/audio/record-stop${this.selectedRecordingTone}.mp3`);
        stop.play();
      };
      start.play();
    }
  }

  async openDeleteShowModal() {
    const show = this.dashboardShow$.value;
    const parentModal = await this.modalController.getTop();
    const modal = await this.modalController.create({
      component: ActionConfirmPage,
      componentProps: {
        title: 'Delete Show',
        message: `Are you sure you want to delete ${show.showName}? This will remove all of it's SESSIONS and RECORDINGS.`,
        img: show.showImg,
        buttons: [
          {
            label: 'Delete Show',
            handler: async () => {
              try {
                await this.lafayetteService.deleteShow(show.showID, this.userService.activeUser$.value);
                parentModal.dismiss();
                this.router.navigate(['/dashboard'], { replaceUrl: true });

                const event: WebhookEvent = {
                  name: WebhookEventNames.SHOW_DELETED,
                  orgID: show.orgID,
                  showID: show.showID,
                  showName: show.showName,
                };
                await this.califoneService.emitWebhookEvent(event, show.orgID);

                this.toastrService.success(null, `Successfully deleted Show`, {
                  progressBar: true,
                  progressAnimation: 'decreasing',
                  closeButton: true,
                  tapToDismiss: false,
                  timeOut: 5 * 1000,
                  toastComponent: GeneralToastComponent,
                });
                await this.analyticsService.track('deleted show', { showID: show.showID, success: true });
              } catch (error) {
                this.toastrService.error(null, `Failed to delete Show`, {
                  progressBar: true,
                  progressAnimation: 'decreasing',
                  closeButton: true,
                  tapToDismiss: false,
                  timeOut: 5 * 1000,
                  toastComponent: GeneralToastComponent,
                });
                await this.analyticsService.track('deleted show', { showID: show.showID, success: false });
              }
              modal.dismiss();
            },
            type: 'danger',
          },
          {
            label: 'Cancel',
            handler: () => {
              modal.dismiss();
              this.analyticsService.track('closed delete show modal', { showID: show.showID });
            },
          },
        ],
      },
      showBackdrop: true,
      backdropDismiss: true,
      animated: true,
      cssClass: 'action-confirm-modal',
    });

    modal.present();
    this.analyticsService.track('selected delete show', { showID: show.showID });
  }

  toggleNotifyBackstage(event: ICustomEvent) {
    this.settingsService.setShowSettings(
      { showToastOnBackstageJoin: event.detail.checked },
      this.dashboardShow$.value.showID
    );
    this.analyticsService.track(`toggled show toast on backstage join`, {
      showID: this.dashboardShow$.value.showID,
      value: event.detail.value,
    });
  }

  toggleMuteConfAudioNotifications(event: ICustomEvent) {
    this.settingsService.setShowSettings(
      { muteConfAudioNotifications: event.detail.checked },
      this.dashboardShow$.value.showID
    );
    this.analyticsService.track(`toggled mute conf audio notifications`, {
      showID: this.dashboardShow$.value.showID,
      value: event.detail.value,
    });
  }

  toggleChatInShow(event: ICustomEvent) {
    this.settingsService.setShowSettings({ enableChat: event.detail.checked }, this.dashboardShow$.value.showID);
    this.analyticsService.track(`toggled chat in show`, {
      showID: this.dashboardShow$.value.showID,
      value: event.detail.value,
    });
  }

  ngOnDestroy(): void {
    this.reset();
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
