import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  addDoc,
  doc,
  docData,
  setDoc,
  deleteDoc,
  collectionData,
  query,
  where,
  orderBy,
  CollectionReference,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from '@angular/fire/firestore';
import { Observable, firstValueFrom } from 'rxjs';

import { Themes } from '@sc/types';
import { ThemeService } from '../theme/theme.service';
import { AnalyticsService } from '../analytics/analytics.service';
import { UserService } from '../user/user.service';
import { GifService } from '../gif/gif.service';
import { ChatGroup, DefaultChatGroups } from '@sc/types';
import { ChatMessage } from '@sc/types';
import { ChatReaction } from '@sc/types';
import { SCSubject } from '../../util/sc-subject.class';
import { SessionsService } from '../sessions/sessions.service';
import { RolesService } from '../roles/roles.service';
import { Participant } from '@sc/types';
import { StudioSidebarService } from '../studio-sidebar/studio-sidebar.service';
import { ColorsService } from '../colors/colors.service';
import { DolbyService } from '../dolby/dolby.service';

import * as dayjs from 'dayjs';
import * as isToday from 'dayjs/plugin/isToday';
import { SupportCenterService } from '../support-center/support-center.service';
import { NameService } from '../name/name.service';
import { limit } from 'firebase/firestore';
dayjs.extend(isToday);

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  studioSession$ = this.sessionsService.studioSession$;
  role$ = this.rolesService.role$;
  unreadChatGroups$ = new SCSubject<string[]>([]);
  chatsCol = collection(this.firestore, 'chats');

  name: string;
  sessionID: string;
  colors: Array<string> = [
    'sc-color-accent-orange-100-bg',
    'sc-color-accent-blue-100-bg',
    'sc-color-accent-1-bg',
    'sc-color-accent-2-bg',
    'sc-color-accent-3-bg',
    'sc-color-accent-4-bg',
    'sc-color-accent-5-bg',
    'sc-color-accent-6-bg',
    'sc-color-accent-7-bg',
    'sc-color-accent-8-bg',
    'sc-color-accent-9-bg',
    'sc-color-accent-11-bg',
    'sc-color-accent-12-bg',
  ];

  private user$ = this.userService.activeUser$;

  constructor(
    private firestore: Firestore,
    private themeService: ThemeService,
    private analyticsService: AnalyticsService,
    private userService: UserService,
    private rolesService: RolesService,
    private gifService: GifService,
    private nameService: NameService,
    private sessionsService: SessionsService,
    private studioSidebarService: StudioSidebarService,
    private supportCenterService: SupportCenterService,
    private colorsService: ColorsService,
    private dolbyService: DolbyService
  ) {
    this.setupChatGroupAndLocationSync();
    this.setupName();
  }

  /**
   * creates a new chat group and sets the name property
   *
   * @param sessionID
   * @param chatGroup
   */
  async createChatGroup(sessionID: string, name: string, addCreator = true) {
    const groupsRef = collection(this.chatsCol, sessionID, 'groups');
    return await addDoc(groupsRef, {
      name,
      users: addCreator ? [this.user$.value.uid] : [],
      color: this.colorsService.getRandomAccentColor(),
    }).catch((error) => {
      // https://github.com/firebase/firebase-js-sdk/issues/5549#issuecomment-1436077246
      return doc(groupsRef, error.message.split('/').at(-1));
    });
  }

  /**
   * gets a collection of chat groups for a given session and participant
   *
   * @param sessionID
   * @param uid
   * @returns
   */
  getParticipantChatGroups(sessionID: string, uid: string) {
    const groupRef = collection(this.chatsCol, sessionID, 'groups');
    return collectionData<ChatGroup>(query(groupRef, where('users', 'array-contains', uid)), {
      idField: 'chatGroupID',
    });
  }

  /**
   * gets a collection of chat groups for a given session
   *
   * @param sessionID
   * @param uid
   * @returns
   */
  getSessionChatGroups(sessionID: string) {
    return collectionData<ChatGroup>(collection(this.chatsCol, sessionID, 'groups'), {
      idField: 'chatGroupID',
    });
  }

  /**
   * gets a specific chat group by ID
   *
   * @param sessionID
   * @param chatGroupID
   */
  getChatGroupByID(sessionID: string, chatGroupID: string) {
    return docData<ChatGroup>(doc(this.chatsCol, sessionID, 'groups', chatGroupID), { idField: 'chatGroupID' });
  }

  /**
   * create a new chat message within a given session and group
   *
   * @param sessionID
   * @param chatGroupID
   * @param chatMessage
   */
  createChatMessage(sessionID: string, chatGroupID: string, chatMessage: ChatMessage) {
    const messageRef = collection(this.chatsCol, sessionID, 'groups', chatGroupID, 'messages');
    return addDoc(messageRef, chatMessage).catch((error) => {
      // https://github.com/firebase/firebase-js-sdk/issues/5549#issuecomment-1436077246
      return doc(messageRef, error.message.split('/').at(-1));
    });
  }

  /**
   * get chat messages from a given session and group
   *
   * @param sessionID
   * @param chatGroupID
   */
  getChatMessages(sessionID: string, chatGroupID: string): Observable<Array<ChatMessage>> {
    const messagesRef = collection(
      this.chatsCol,
      sessionID,
      'groups',
      chatGroupID,
      'messages'
    ) as CollectionReference<ChatMessage>;
    return collectionData(query(messagesRef, orderBy('datetime', 'desc'), limit(150)), {
      idField: 'chatMessageID',
    });
  }

  /**
   * creates a new chat reaction on a given chat group and chat message
   *
   * @param sessionID
   * @param chatGroupID
   * @param chatMessageID
   * @param chatReaction
   */
  createChatReaction(sessionID: string, chatGroupID: string, chatMessageID: string, chatReaction: ChatReaction) {
    const reactionsRef = collection(
      this.chatsCol,
      sessionID,
      'groups',
      chatGroupID,
      'messages',
      chatMessageID,
      'reactions'
    );
    return addDoc(reactionsRef, chatReaction).catch((error) => {
      // https://github.com/firebase/firebase-js-sdk/issues/5549#issuecomment-1436077246
      return doc(reactionsRef, error.message.split('/').at(-1));
    });
  }

  /**
   * gets all chat reactions for a given chat group and chat message
   *
   * @param sessionID
   * @param chatGroupID
   * @param chatMessageID
   */
  getChatReactions(sessionID: string, chatGroupID: string, chatMessageID: string) {
    const reactionsRef = collection(
      this.chatsCol,
      sessionID,
      'groups',
      chatGroupID,
      'messages',
      chatMessageID,
      'reactions'
    ) as CollectionReference<ChatReaction>;
    return collectionData(reactionsRef, { idField: 'chatReactionID' });
  }

  /**
   * updates a chat reaction for a given chat group and chat message
   *
   * @param sessionID
   * @param chatGroupID
   * @param chatMessageID
   * @param chatReactionID
   * @param chatReaction
   */
  updateChatReaction(
    sessionID: string,
    chatGroupID: string,
    chatMessageID: string,
    chatReactionID: string,
    chatReaction: ChatReaction
  ) {
    return setDoc(
      doc(this.chatsCol, sessionID, 'groups', chatGroupID, 'messages', chatMessageID, 'reactions', chatReactionID),
      chatReaction
    );
  }

  /**
   * deletes a chat reaction for a given chat group and chat message
   *
   * @param sessionID
   * @param chatGroupID
   * @param chatMessageID
   * @param chatReactionID
   */
  deleteChatReaction(sessionID: string, chatGroupID: string, chatMessageID: string, chatReactionID: string) {
    return deleteDoc(
      doc(this.chatsCol, sessionID, 'groups', chatGroupID, 'messages', chatMessageID, 'reactions', chatReactionID)
    );
  }

  /**
   * Checks for a DM chat group with the participant to avoid duplicates
   * and ensures history between the participants. If a chat group doesn't
   * already exist, create it and add the participant then set the active
   * chat group and open the chat sidebar
   */
  async directMessage(participant: Participant) {
    const existingChatGroup = (
      await firstValueFrom(
        this.getParticipantChatGroups(this.studioSession$.value.sessionID, this.userService.activeUser$.value.uid)
      )
    ).find(
      (chatGroup) =>
        chatGroup.users.length === 2 &&
        chatGroup.name.toLowerCase() !== DefaultChatGroups.STAGE &&
        chatGroup.name.toLowerCase() !== DefaultChatGroups.BACKSTAGE &&
        chatGroup.name.toLowerCase() !== DefaultChatGroups.EVERYONE &&
        chatGroup.users.includes(participant.uid) &&
        chatGroup.users.includes(this.userService.activeUser$.value.uid)
    );

    if (existingChatGroup) {
      this.studioSidebarService.openChatGroup(existingChatGroup.chatGroupID);
    } else {
      const chatGroupRef = await this.createChatGroup(
        this.studioSession$.value.sessionID,
        `${participant.name} + ${this.name}`
      );
      await this.addParticipantToChatGroup(this.studioSession$.value.sessionID, chatGroupRef.id, participant.uid);
      this.studioSidebarService.openChatGroup(chatGroupRef.id);
    }
  }

  getChats(sessionID: string) {
    return collectionData(query(this.chatsCol, where('sessionID', '==', sessionID)), { idField: 'chatGroupID' });
  }

  /**
   * Adds a participant to a chat group
   *
   * @param sessionID
   * @param chatGroupID
   * @param participant
   */
  addParticipantToChatGroup(sessionID: string, chatGroupID: string, uid: string) {
    return updateDoc(doc(this.chatsCol, sessionID, 'groups', chatGroupID), {
      users: arrayUnion(uid),
    });
  }

  /**
   * Sets participants in a chat group
   *
   * @param sessionID
   * @param chatGroupID
   * @param uIDs
   */
  setParticipantsInChatGroup(sessionID: string, chatGroupID: string, uIDs: Array<string>) {
    return setDoc(doc(this.chatsCol, sessionID, 'groups', chatGroupID), { users: uIDs }, { merge: true });
  }

  /**
   * Gets chat group by name
   *
   * @param sessionID
   * @param name
   */
  getChatGroupByName(sessionID: string, name: string) {
    const chatsRef = collection(this.chatsCol, sessionID, 'groups');
    return collectionData<ChatGroup>(query(chatsRef, where('name', '==', name)), {
      idField: 'chatGroupID',
    });
  }

  /**
   * Removes a participant from a chat group
   *
   * @param sessionID
   * @param chatGroupID
   * @param participant
   */
  removeParticipantFromChatGroup(sessionID: string, chatGroupID: string, uid: string) {
    return updateDoc(doc(this.chatsCol, sessionID, 'groups', chatGroupID), {
      users: arrayRemove(uid),
    });
  }

  handleChatCommand(
    chatMessage: ChatMessage,
    chatGroupID: string
  ): { chatCommand: string; message?: string; error?: string; chatMessage?: ChatMessage } {
    const args = chatMessage.message.split(' ');
    const command = args.shift();

    switch (command) {
      case '/?':
      case '/help':
        this.supportCenterService.show();
        return {
          chatCommand: 'openMenu',
        };
      case '/dark':
        this.themeService.setTheme(Themes.DARK);
        this.analyticsService.track('changed theme from chat');
        return {
          chatCommand: 'dark',
          message: 'Theme changed: Dark',
        };
      case '/light':
        this.themeService.setTheme(Themes.LIGHT);
        this.analyticsService.track('changed theme from chat');
        return {
          chatCommand: 'light',
          message: 'Theme changed: Light',
        };
      case '/roll': {
        const roll = this.handleCommandRoll(args);
        chatMessage.message = `🎲 (${roll[1]}): ${roll[0]}`;
        this.createChatMessage(this.studioSession$.value.sessionID, chatGroupID, chatMessage);
        return {
          chatCommand: 'roll',
        };
      }
      case '/gif':
        return {
          chatCommand: 'gif',
          chatMessage,
        };
      default:
        return {
          chatCommand: 'unknown',
          error: `No command found: ${command}`,
        };
    }
  }

  handleCommandRoll(args: Array<string>) {
    let numDice = 1;
    let diceSize = 20;
    if (args[0]) {
      const isDigit = /^\d+$/;
      const isDice = /^(\d+)d(\d+)$/;
      if (isDigit.test(args[0])) {
        diceSize = parseInt(args[0], 10);
      } else if (isDice.test(args[0])) {
        const values = args[0].split('d');
        numDice = parseInt(values[0], 10);
        diceSize = parseInt(values[1], 10);
      }
    }
    const prettyName = `${numDice}d${diceSize}`;
    const rollResult = Math.ceil(Math.random() * numDice * diceSize);
    return [rollResult, prettyName];
  }

  async setupName() {
    await this.sessionsService.studioSessionID$.nextExistingValue();
    this.nameService.getName(this.sessionsService.studioSessionID$.value, this.user$.value.uid).subscribe((name) => {
      this.name = name || 'Unknown 👀';
    });
  }

  async setupChatGroupAndLocationSync() {
    this.dolbyService.activeParticipants$.subscribe(async (active) => {
      if (!active) return;
      const everyoneIDs = active.participants.map((p) => p.info.externalId).filter((uid) => uid);
      const stageIDs = active.participants.map((p) => p.type === 'user' && p.info.externalId).filter((uid) => uid);
      const listenerIDs = active.participants
        .map((p) => p.type === 'listener' && p.info.externalId)
        .filter((uid) => uid);
      const sessionID = await this.sessionsService.studioSessionID$.nextExistingValue();

      const groups = (await firstValueFrom(this.getSessionChatGroups(sessionID))) ?? [];
      const stageGroup = groups.find((g) => g.name === DefaultChatGroups.STAGE);
      const everyoneGroup = groups.find((g) => g.name === DefaultChatGroups.EVERYONE);
      const listenerGroup = groups.find((g) => g.name === DefaultChatGroups.BACKSTAGE);

      if (stageGroup) {
        this.setParticipantsInChatGroup(sessionID, stageGroup.chatGroupID, stageIDs);
      }
      if (everyoneGroup) {
        this.setParticipantsInChatGroup(sessionID, everyoneGroup.chatGroupID, everyoneIDs);
      }
      if (listenerGroup) {
        this.setParticipantsInChatGroup(sessionID, listenerGroup.chatGroupID, listenerIDs);
      }
    });

    this.sessionsService.studioSessionID$.subscribe(async (sessionID) => {
      if (sessionID) {
        const groups = (await firstValueFrom(this.getSessionChatGroups(sessionID))) ?? [];

        if (!groups.find((g) => g.name === DefaultChatGroups.BACKSTAGE)) {
          this.createChatGroup(this.studioSession$.value.sessionID, DefaultChatGroups.BACKSTAGE, false);
        }
        if (!groups.find((g) => g.name === DefaultChatGroups.STAGE)) {
          this.createChatGroup(this.studioSession$.value.sessionID, DefaultChatGroups.STAGE, false);
        }
        if (!groups.find((g) => g.name === DefaultChatGroups.EVERYONE)) {
          this.createChatGroup(this.studioSession$.value.sessionID, DefaultChatGroups.EVERYONE, false);
        }
      }
    });
  }
}
